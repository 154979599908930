import React, { useState, useRef } from "react";
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Alert } from '@strapi/design-system/Alert';
import { Box } from '@strapi/design-system/Box';
import PaymentMethodForm from "./PaymentMethodForm";

import Strings from "../constants/Strings";

import ScreenNames from "../constants/ScreenNames";

export default function PaymentBox(props){

    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [showAlert, setShowAlert] = useState({ active: false, variant: 'success', body: Strings.paymentMethodUpdatedSuccessfully });
    const [isVisible, setIsVisible] = useState(false);

    //Form
    const paymentMethodFormRef = useRef();

    const onFormComplete = async() => {
        setLoading(false);
        setIsVisible(false);
        window.location.href = props.to || ScreenNames.PurchaseConfirmation;
    }

    return <>
        {showAlert.active && <Alert closeLabel="Close alert" variant={showAlert.variant} onClose={() => setShowAlert({...showAlert, active: false })} children={showAlert.body} style={{ position: 'absolute', top: 20, right: 20, alignSelf: 'center', justifyContent: 'center' }} />}

        <Box style={{ width: '100%' }}>
            <PaymentMethodForm update={props?.update} ref={paymentMethodFormRef} onError={(error) => { console.log({ error }) }} onComplete={onFormComplete} />
        </Box>
    </>
    
}