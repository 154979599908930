import React from "react";
import { useHistory } from "react-router-dom";
import { Stack } from '@strapi/design-system/Stack';
import { Flex } from '@strapi/design-system/Flex';
import { Typography } from '@strapi/design-system/Typography';
import { Grid, GridItem } from '@strapi/design-system/Grid';
import SubscriptionBox from "../../components/SubscriptionBox";
import PaymentMethodBox from "../../components/PaymentMethodBox";

import Strings from "../../constants/Strings";
import { customized, styleSettings } from "../../config";
import useUser from "../../hooks/useUser";
import useDeviceTypes from "../../hooks/useDeviceTypes";
import { StepperOne } from "../../components/custom/Steppers";
import ScreenNames from "../../constants/ScreenNames";

export default function RetentionOfferScreen(props) {

    const history = useHistory();
    const { token } = useUser();
    const { isWidth } = useDeviceTypes();

    if(!token) history.push(ScreenNames.SignIn);
    return (
        <Flex direction="column">
            <Typography variant={styleSettings.pageHeader}>{'VisualDx Special Promotion'}</Typography>
            <Typography style={{ marginTop: 5 }} variant={styleSettings.pageSubHeader}>{`We'd love you to stick around. Take advantage of this reduced rate and keep access.`}</Typography>

            <Grid gap={[5, 2, 3]} style={{ width: isWidth, marginTop: 30 }}>
                <GridItem padding={0} col={4} s={12}>
                    <Stack spacing={3} padding={0}>
                        <PaymentMethodBox />
                    </Stack>
                </GridItem>
                <GridItem padding={0} col={8} s={12}>
                    <Stack spacing={3} padding={0}>
                        <SubscriptionBox dryrun={true} title={Strings.subscriptionSummary} summary={true} />
                    </Stack>
                </GridItem>
            </Grid>
        </Flex>
    )
}