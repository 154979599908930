import axios from 'axios';
import _ from 'lodash';
import * as jose from 'jose';

const baseURL = _.get(process.env, 'REACT_APP_API_URL');
const cashboxUsername = _.get(process.env, 'REACT_APP_CASHBOX_USERNAME');
const cashboxPassword = _.get(process.env, 'REACT_APP_CASHBOX_PASSWORD');
const auth = { username: cashboxUsername, password: cashboxPassword };

export const apiProducts = {
    list: (async ( payload ) => {
        const request = {
            method: 'get',
            baseURL: baseURL,
            auth,
            url: '/products',
            params: {
                status: 'Active',
                ...payload,
            }
        };
        
        return await axios( request );
    }),
    get: (async ( id ) => {
        const request = {
            method: 'get',
            baseURL: baseURL,
            auth,
            url: '/products/' + id,
        };
        
        return await axios( request );
    }),
}

export const apiPaymentMethods = {
    update: (async ( id, payload ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'put',
            baseURL: baseURL,
            auth,
            url: '/payment_methods/' + id,
            // headers: { headers: { Authorization: `Bearer ${token}` }},
            data: payload
        };
        
        return await axios( request );
    }),
}

export const apiAccounts = {
    create: (async ( payload ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'post',
            baseURL: baseURL,
            auth,
            url: '/accounts',
            // headers: { headers: { Authorization: `Bearer ${token}` }},
            data: payload
        };
        
        return await axios( request );
    }),
    update: (async ( id, payload ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'put',
            baseURL: baseURL,
            auth,
            url: '/accounts/' + id,
            // headers: { headers: { Authorization: `Bearer ${token}` }},
            data: payload
        };
        
        return await axios( request );
    }),
    list: (async ( payload ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            auth,
            url: '/accounts',
            // headers: { headers: { Authorization: `Bearer ${token}` }},
            params: payload
        };
        
        return await axios( request );
    }),
    refresh: (async ( payload ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            auth,
            url: '/accounts/refresh',
            // headers: { headers: { Authorization: `Bearer ${token}` }},
            params: payload
        };
        
        return await axios( request );
    }),
    get: (async ( id ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            auth,
            url: '/accounts/' + id,
            // headers: { headers: { Authorization: `Bearer ${token}` }},
        };
        
        return await axios( request );
    }),
}

export const apiAddDiscount = {
    update: (async ( id, payload, dryrun ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'put',
            baseURL: baseURL,
            auth,
            url: `/subscriptions/${id}/add_discount_ticket`,
            data: payload,
            // headers: { headers: { Authorization: `Bearer ${token}` }},
            params: {
                dryrun
            }
        };
        
        return await axios( request );
    })
}

export const apiSubscriptions = {
    create: (async ( payload, dryrun = false ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'post',
            baseURL: baseURL,
            auth,
            url: '/subscriptions',
            // headers: { headers: { Authorization: `Bearer ${token}` }},
            data: payload,
        };

        if(dryrun) request.params = { dryrun: true }
        
        return await axios( request );
    }),
    modify: (async ( id, payload, dryrun = false, effective_date = 'today', bill_prorated_period = true) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'put',
            baseURL: baseURL,
            auth,
            url: '/subscriptions/' + id,
            data: payload,
            // headers: { headers: { Authorization: `Bearer ${token}` }},
            params: {
                bill_prorated_period,
                dryrun,
                effective_date,
            }
        };

        if(!effective_date) delete request.params.effective_date;
        
        return await axios( request );
    }),
    update: (async ( id, payload, dryrun = false ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'put',
            baseURL: baseURL,
            auth,
            url: '/subscriptions/' + id,
            data: payload,
            // headers: { headers: { Authorization: `Bearer ${token}` }},
            params: {
                dryrun
            }
        };
        
        return await axios( request );
    }),
    list: (async ( payload ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            auth,
            url: '/subscriptions',
            // headers: { headers: { Authorization: `Bearer ${token}` }},
            params: payload
        };
        
        return await axios( request );
    }),
    get: (async ( id ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            auth,
            // headers: { headers: { Authorization: `Bearer ${token}` }},
            url: '/subscriptions/' + id,
        };
        
        return await axios( request );
    }),
    activate: (async ( id ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            auth,
            url: '/subscriptions/' + id + '/activate',
            // headers: { headers: { Authorization: `Bearer ${token}` }},
        };
        
        return await axios( request );
    }),
    cancel: (async ( id ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            auth,
            url: '/subscriptions/' + id + '/cancel',
            // headers: { headers: { Authorization: `Bearer ${token}` }},
        };
        
        return await axios( request );
    }),
}

export const apiCharges = {
    create: (async ( payload, dryrun = false ) => {
        const request = {
            method: 'post',
            baseURL: baseURL,
            auth,
            url: '/charges',
            data: payload,
        };

        if(dryrun) request.params = { dryrun: true }
        
        return await axios( request );
    })
}

export const apiInvoices = {       
    list: (async ( payload ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            auth,
            url: '/invoices',
            // headers: { headers: { Authorization: `Bearer ${token}` }},
            params : payload
        };
        
        return await axios( request );
    }),
    get: (async ( id ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            auth,
            url: '/invoices/' + id,
            // headers: { headers: { Authorization: `Bearer ${token}` }},
        };
        
        return await axios( request );
    }),
}

export const apiTransactions = {       
    list: (async ( payload ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            auth,
            url: '/transactions',
            // headers: { headers: { Authorization: `Bearer ${token}` }},
            params : payload
        };
        
        return await axios( request );
    }),
    get: (async ( id ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            auth,
            url: '/transactions/' + id,
            // headers: { headers: { Authorization: `Bearer ${token}` }},
        };
        
        return await axios( request );
    }),
}

export const apiAuth = {
    verify: (async ( jwt ) => {
        const request = {
            method: 'post',
            baseURL: baseURL,
            auth,
            url: '/auth/verify',
            data: {
                jwt
            }
        };
        
        return await axios( request );
    }),
    verifyMe: (async ( jwt ) => {
        const fetchedPublicKey = await fetchPublicKey();
        console.log('jwk', fetchedPublicKey.data.keys[0]);
        const alg = process.env.REACT_APP_JWT_ALG;
        console.log('alg', alg);
        // delete fetchedPublicKey.data.keys[0].kid;
        const jwk = fetchedPublicKey.data.keys[0];
        const publicKey = await jose.importJWK(jwk, alg);
        console.log('publicKey', publicKey);
        const { payload } = await jose.jwtVerify( jwt, publicKey );
        console.log('payload', payload);
        return payload;
        
    }),
}

export const apiGoogle = {    
    placeAutocomplete: (async({ types = 'geocode', input }) => {
        const request = {
            method: 'get',
            baseURL: `https://maps.googleapis.com/maps/api/place/autocomplete/json`,
            params: {
                key: process.env.REACT_APP_GOOGLE_API_KEY,
                types,
                input
            }
        };

        return axios(request);
    })
}

export const apiCampaigns = {
    get: (async (id) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            auth,
            url: '/campaigns/' + id,
            // headers: { headers: { Authorization: `Bearer ${token}` }},
        };        
        return await axios(request);
    }),
}

export const apiCampaignCode = {
    get: (async ( id ) => {
        const token = localStorage.getItem('token');
        const request = {
            method: 'get',
            baseURL: baseURL,
            auth,
            url: '/campaign_codes/' + id,
            // headers: { headers: { Authorization: `Bearer ${token}` }},
        };        
        return await axios( request );
    }),
}

export const fetchPublicKey = async () => {

    try {

        const request = {
            method: 'get',
            auth,
            baseURL: process.env.REACT_APP_JWT_KEY_URL
        };

        return await axios( request );

    } catch (error) {
        console.log('fetchPublicKey Error: ', error);
        return _.get( error, 'response', false );
    }
};

export const certCaptureGetToken = {
    get: (async ( customer_number ) => {
        const request = {
            method: 'post',
            baseURL: baseURL,
            auth,
            url: '/certcapture/get-token',
            data: {
                customer_number
            },
        };
        return await axios( request );
    }),
}

export const certCaptureUpsertCustomer = {
    put: (async ( payload ) => {
        const request = {
            method: 'post',
            baseURL: baseURL,
            auth,
            url: '/certcapture/customer',
            data: payload,
        };        
        return await axios( request );
    }),
}

export const logErrorService = {
    create: (async ( payload ) => {
        const request = {
            method: 'post',
            baseURL: baseURL,
            auth,
            url: '/log/error',
            data: payload,
        };        
        return await axios( request );
    }),
}