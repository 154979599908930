import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Flex, Typography } from '@strapi/design-system';
import { styleSettings } from "../../config";

import useDeviceTypes from "../../hooks/useDeviceTypes";
import ScreenNames from "../../constants/ScreenNames";

export default function ErrorScreen(props) {
  const history = useHistory();
  const { isMobileTablet } = useDeviceTypes();
  const [error, setError] = useState({
    title: 'Oops! Something went wrong.',
    message: 'Please try again later or contact support.'
  });
  // Get the error code from the URL - from useLocation
  const location = useLocation();
  const errorCode = location.pathname.split('/').pop();  

  useEffect(() => {
    switch (errorCode) {
      case '404':
        setError({
          title: 'Oops! Something went wrong.',
          message: 'Please try again later or contact support.'
        });
        break;
      default:
        break;
    };
  }, [errorCode]);

  return (
    <Flex alignItems="center" justifyContent="center">
      <Flex alignItems="center" direction="column">
        <Typography variant={styleSettings.pageHeader}>{error.title}</Typography>
        <Typography style={{ marginTop: 20 }}>{error.message}</Typography>
      </Flex>
    </Flex>
  )
}