
const demoMode = process.env.REACT_APP_DEMO_MODE;
const stage = process.env.REACT_APP_STOREFRONT_ENV;
const devMode = process.env.NODE_ENV !== 'production';
const client = 'VisualDx';
const appName = 'VisualDx Storefront';

const clientContact = {
    name: client,
    email: 'contact@visualdx.com',
    phone: '(800) 357-9296',
    address: '100 Corporate Woods, Suite 100, Rochester, NY 14623',
};

const branding = {
    themes: {
      light: {
          colors: {
            alternative100: '#f6ecfc',
            alternative200: '#e0c1f4',
            alternative500: '#ac73e6',
            alternative600: '#9736e8',
            alternative700: '#8312d1',
            buttonNeutral0: '#ffffff',
            buttonPrimary500: '#7b79ff',
            buttonPrimary600: '#4945ff',
            danger100: '#fcecea',
            danger200: '#f5c0b8',
            danger500: '#ee5e52',
            danger600: '#d02b20',
            danger700: '#b72b1a',
            neutral0: '#ffffff',
            neutral100: '#f6f6f9',
            neutral1000: '#181826',
            neutral150: '#eaeaef',
            neutral200: '#dcdce4',
            neutral300: '#c0c0cf',
            neutral400: '#a5a5ba',
            neutral500: '#8e8ea9',
            neutral600: '#666687',
            neutral700: '#4a4a6a',
            neutral800: '#32324d',
            primary200: '#212134',
            primary100: '#f0f0ff',
            primary200: '#d9d8ff',
            primary500: '#7b79ff',
            primary600: '#4945ff',
            primary700: '#271fe0',
            secondary100: '#eaf5ff',
            secondary200: '#b8e1ff',
            secondary500: '#66b7f1',
            secondary600: '#0c75af',
            secondary700: '#006096',
            success100: '#eafbe7',
            success200: '#c6f0c2',
            success500: '#5cb176',
            success600: '#328048',
            success700: '#2f6846',
            warning100: '#fdf4dc',
            warning200: '#fae7b9',
            warning500: '#f29d41',
            warning600: '#d9822f',
            warning700: '#be5d01',
        },
        shadows: {
            filterShadow: '0px 1px 4px rgba(33, 33, 52, 0.1)',
            focus: 'inset 2px 0px 0px rgb(39, 31, 224), inset 0px 2px 0px rgb(39, 31, 224), inset -2px 0px 0px rgb(39, 31, 224), inset 0px -2px 0px rgb(39, 31, 224)',
            focusShadow: '0px 0px 6px rgba(76, 191, 255, 0.75)',
            popupShadow: '0px 2px 15px rgba(33, 33, 52, 0.1)',
            tableShadow: '0px 1px 4px rgba(33, 33, 52, 0.1)',
        },
        sizes: {
            combobox: `${50 / 16}rem`,
            input: {
                S: `${32 / 16}rem`,
                M: `${40 / 16}rem`,
                L: `${50 / 16}rem`,
            },
            accordions: {
                S: `${48 / 16}rem`,
                M: `${88 / 16}rem`,
            },
        },
        zIndices: [
            5,
            10,
            15,
            20
        ],
        spaces: [
            "0px",
            "4px",
            "8px",
            "12px",
            "16px",
            "20px",
            "24px",
            "32px",
            "40px",
            "48px",
            "56px",
            "64px"
        ],
        //borderRadius: "4px",
        borderRadius: "10px",
        mediaQueries: {
            tablet: `@media (max-width: ${1100 / 16}rem)`,
            mobile: `@media (max-width: ${550 / 16}rem)`,
        },
        // fontSizes: [`${11 / 16}rem`, `${12 / 16}rem`, `${14 / 16}rem`, '1rem', `${18 / 16}rem`, `${32 / 16}rem`], //original
        fontSizes: [`${11 / 19}rem`, `${12 / 19}rem`, `${14 / 19}rem`, '1rem', `${18 / 19}rem`, `${32 / 19}rem`],
        lineHeights: [
            1.14,
            1.22,
            1.25,
            1.33,
            1.43,
            1.45,
            1.5
        ],
        fontWeights: {
            regular: 400,
            semiBold: 500,
            bold: 600
        }
      },
      dark: {
        colors: {
            alternative100: '#181826',
            alternative200: '#4a4a6a',
            alternative500: '#ac73e6',
            alternative600: '#ac73e6',
            alternative700: '#e0c1f4',
            buttonNeutral0: '#ffffff',
            buttonPrimary500: '#348cad',
            buttonPrimary600: '#177CA1',
            danger100: '#181826',
            danger200: '#4a4a6a',
            danger500: '#ee5e52',
            danger600: '#ee5e52',
            danger700: '#ee5e52',
            neutral0: '#121A27',
            neutral100: '#090E17',
            neutral1000: '#ffffff',
            neutral150: '#222A38',
            neutral200: '#292929', // input border
            neutral300: '#A0A8B6',
            neutral400: '#a5a5ba',
            neutral500: '#c0c0cf',
            neutral600: '#d3d3d3',
            neutral700: '#eaeaef',
            neutral800: '#ffffff',
            neutral900: '#ffffff',
            primary100: '#3D4554',
            primary200: '#A4AAB6',
            primary500: '#66b7f1',
            primary600: '#7CD6F7',
            primary700: '#fff',
            secondary100: '#181826',
            secondary200: '#4a4a6a',
            secondary500: '#66b7f1',
            secondary600: '#66b7f1',
            secondary700: '#b8e1ff',
            success100: '#181826',
            success200: '#4a4a6a',
            success500: '#348cad', // 5cb176
            success600: '#348cad', // 5cb176
            success700: '#c6f0c2',
            warning100: '#181826',
            warning200: '#4a4a6a',
            warning500: '#f29d41',
            warning600: '#f29d41',
            warning700: '#fae7b9',
        },
        shadows: {
            filterShadow: '1px 1px 10px rgba(3, 3, 5, 0.35)',
            focus: 'inset 2px 0px 0px rgb(39, 31, 224), inset 0px 2px 0px rgb(39, 31, 224), inset -2px 0px 0px rgb(39, 31, 224), inset 0px -2px 0px rgb(39, 31, 224)',
            focusShadow: '0px 0px 6px rgba(76, 191, 255, 0.75)',
            popupShadow: '1px 1px 10px rgba(3, 3, 5, 0.35)',
            tableShadow: '1px 1px 10px rgba(3, 3, 5, 0.2)',
        },
        sizes: {
            combobox: `44px`,
            input: {
                S: `${32 / 16}rem`,
                M: `${40 / 16}rem`,
                L: `${47 / 16}rem`,
            },
            accordions: {
                S: `${48 / 16}rem`,
                M: `${88 / 16}rem`,
            },
        },
        zIndices: [
            5,
            10,
            15,
            20
        ],
        spaces: [
            "0px",
            "4px",
            "8px",
            "12px",
            "16px",
            "20px",
            "24px",
            "32px",
            "40px",
            "48px",
            "56px",
            "64px"
        ],
        borderRadius: "7px",
        mediaQueries: {
            tablet: `@media (max-width: ${1100 / 16}rem)`,
            mobile: `@media (max-width: ${550 / 16}rem)`,
        },
        // fontSizes: [`${11 / 16}rem`, `${12 / 16}rem`, `${14 / 16}rem`, '1rem', `${18 / 16}rem`, `${32 / 16}rem`], //original
        //fontSizes: [`${11 / 20}rem`, `${12 / 20}rem`, `${14 / 20}rem`, '1rem', `${18 / 20}rem`, `${32 / 20}rem`],
        fontSizes: ['12px', '16px','16px', '18px', '18px', '30px'],
        lineHeights: [
            1.14,
            1.22,
            1.25,
            1.33,
            1.43,
            1.45,
            1.5
        ],
        fontWeights: {
          regular: 400,
          semiBold: 500,
          bold: 600
        }
      }
    },
    logo: {
        light: require('../src/assets/logos/logo.png'),
        dark: require('../src/assets/logos/logo.png'),
    }
};
const appStrings = {
    selectBasePackage: `Confirm a Base Package`,
    packagingByFeaturesTitle: `Packages`,
    packagingByFeaturesSubTitle: `VisualDx Products Packaging by Features`,
    packagingFeatures: `Features Included:`,
    packagingAddOnOptions: `Add-On Options:`,

    //General
    submit: 'Submit',
    next: 'Next',
    selectPackage: 'Select Package',
    selectingPackage: 'Selecting Package',    
    monthlySubscription: 'Monthly Subscription',
    annualSubscription: 'Annual Subscription',


}

const overrides = {
    textInput: { 
        height: 44
    },
}

const styleSettings = {
    pageHeader: 'alpha',
    pageSubHeader: 'beta',
    pageHeaders: {
        variant: 'alpha',
    },
    pageSubHeaders: {
        variant: 'epsilon',
        textColor: 'neutral300',
    },
    packagingByFeatures: {
        card: {
            minHeight: 900,
            width: 350
        }
    },
    backgroundColor: "neutral100",
    cards: {
        shadow: 'none',
        borderColor: 'neutral100',
        selectedBorderColor: 'primary600',
        button: {
            size: 'S',
            variant: 'default',
        }
    },
    modals: {
        title: {
            fontWeight: "bold",
            textColor: "neutral800",
            variant: "epsilon"
        },
        body: {
            background: "neutral100",
        },
        buttons: {
            startAction: {
                variant: "tertiary",
                size: 'S'
            },
            endAction: {
                variant: "default",
                size: 'S'
            },
        }
    },
    contentBox: {
        headerVariant: 'beta',
        lineHeaderVariant: 'omega',
        lineSubHeaderVariant: 'omega',
    },
    table: {
        thText: {
            variant: 'sigma'
        }
    },
    screenSizes: {
        mobile: '95vw',
        tablet: '95vw',
        small: '90vw',
        large: '52vw'
    },
    overrides,
}

const authTypes = {
    SIGN_IN: 'sign_in',
    CREATE_ACCOUNT: 'create_account',
    FORGOT_PASSWORD: 'forgot_password',

}

const currencyFormat = {
    locale: 'en-us',
    format: { 
        style: 'currency', 
        currency: 'USD', 
        // maximumSignificantDigits: 3 
    }
}

//Activate APIs for test mode in /hooks/useAPI.js
const apiDevModes = {
    fetchProductPackages: false,
    fetchSubscription: false,
    fetchPaymentMethod: false,
    fetchAccount: false,
    fetchBillingHistory: false,
    cancelSubscription: false,
    purchaseSubscription: false,
    updateSubscription: false,
    updatePaymentMethod: false,
    signIn: false,
    createAccount: false,
    signOut: false,
    forgotPassword: false,
    resetPasswordWithCode: false,
    submitCancelSurvey: false,
    submitRetentionOffer: false,
}

const upgradeOnlyProducts = [
    {
        from: {
            id: 'VisualDx (Monthly)',
            vid: 'e633c1484756a98293a62e9befc6133c8a13200c'
        },
        to: {
            id: 'VisualDx Plus DermExpert (Monthly)',
            vid: 'ff3ca4c18c8d0a0541eda184486bf8ae81915be6'
        },
    },
    {
        from: {
            id: 'VisualDx (Monthly)',
            vid: 'e633c1484756a98293a62e9befc6133c8a13200c'
        },
        to: {
            id: 'VisualDx Plus DermExpert (Annual)',
            vid: '1b0a33bc40c725b567045d116b4cec43ef2fbb5f'
        },
    },
    {
        from: {
            id: 'VisualDx (Annual)',
            vid: 'eb64cd560529e751f76749f385734b4b190f521e'
        },
        to: {
            id: 'VisualDx Plus DermExpert (Monthly)',
            vid: 'ff3ca4c18c8d0a0541eda184486bf8ae81915be6'
        },
    },
    {
        from: {
            id: 'TEST_2day_notrial9',
            vid: '9a730b3fa017cb3224d4ff36481cef068225eab4'
        },
        to: {
            id: 'TEST 2day notrial50',
            vid: 'e9ac2a017f83560ec5749426ec11fb1fa434f0d2'
        },
    },
    {
        from: {
            id: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? 'VisualDx Core Monthly' : 'VisualDx Core Monthly',
            vid: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? '5a7e16dd8bbfa003caf83a884329e14d00fb5e89' : '3c59fde4e49a1fd9a37b17155e6e3fe836a12720',
        },
        to: {
            d: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? 'VisualDx Elite Monthly' : 'VisualDx Elite Monthly',
            vid: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? '491d7636abff50e178eaee24c1e7ce5dc002fc8e' : 'eba8dd2a506c7f4d72964fe1dcdccf330b4c4351',
        },
    },
    {
        from: {
            id: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? 'VisualDx Core Annual' : 'VisualDx Core Annual',
            vid: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? '6e858459dd66153242b24a6824ba84c4c32955ad' : 'ab1afec07833596edf96b541bcb7f865966c82c8',
        },
        to: {
            d: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? 'VisualDx Elite Annual' : 'VisualDx Elite Annual',
            vid: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? 'aa9a4147ebaa193c283fc24032ec6623e1193753' : '8b8d6c3d01e5b62a21e7b1df31bd06304392f44c',
        },
    },
    // {
    //     from: {
    //         id: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? '' : '',
    //         vid: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? '' : '',
    //     },
    //     to: {
    //         d: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? '' : '',
    //         vid: process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? '' : '',
    //     },
    // },
];

const specialEventOfferBillPlans = [
    {
        param: 'AADShow',
        product: stage === 'prd' ? 'VisualDx Elite Annual' : 'VisualDx Plus DermExpert (Annual)',
        campaign: 'AADShow',
        billPlan: 'YearlyNoTrial'
    },
    {
        param: 'AANPshow',
        product: stage === 'prd' ? 'VisualDx Elite Annual' : 'VisualDx Plus DermExpert (Annual)',
        campaign: 'AANPshow',
        billPlan: 'YearlyNoTrial'
    },
    {
        param: 'ACEPshow',
        product: stage === 'prd' ? 'VisualDx Elite Annual' : 'VisualDx Plus DermExpert (Annual)',
        campaign: 'ACEPshow',
        billPlan: 'YearlyNoTrial'
    },
    {
        param: 'NoTrial',
        product: stage === 'prd' ? 'VisualDx Elite Annual' : 'VisualDx Plus DermExpert (Annual)',
        campaign: 'NoTrial',
        billPlan: 'YearlyNoTrial'
    },
    {
        param: 'WelcomeBack23',
        product: stage === 'prd' ? 'VisualDx Elite Annual' : 'VisualDx Plus DermExpert (Annual)',
        campaign: 'WelcomeBack23',
        billPlan: 'YearlyNoTrial'
    },
    {
        param: 'WINBACK',
        product: stage === 'prd' ? 'VisualDx Elite Annual' : 'VisualDx Plus DermExpert (Annual)',
        campaign: 'WINBACK',
        billPlan: 'YearlyNoTrial'
    },
    {
        param: 'AMWA',
        product: stage === 'prd' ? 'VisualDx Plus DermExpert Annual' : 'VisualDx Plus DermExpert (Annual)',
        campaign: 'AMWA',
        billPlan: stage === 'prd' ? 'Annual 4-month-trial' : 'Test Annual 4-month-trial',
    },
    {
        param: 'ACEP2023',
        product: stage === 'prd' ? 'VisualDx Elite Annual' : 'VisualDx Elite Annual',
        campaign: 'ACEP2023',
        billPlan: 'YearlyNoTrial'
    },
];

const customized = {
    defaultTheme: 'dark',
    mangeBillingAccountExternally: true, 
    stepperActive: true,
    stepperType: 'visualdx',
    cancelSubscriptionSurvey: {
        active: true,
        title: 'Cancel Survey',
        subtitle: 'Before you cancel, please let us know why. Every bit of feedback helps us improve!',
        reasons: [
            {
                id: 'CancelSurvey:visualdx_not_useful',
                text: 'VisualDx is not useful to my clinical practice or continuing education.',
            },
            {
                id: 'CancelSurvey:need_more_information_accuracy',
                text: 'I need more information about the accuracy of the result.',
            },
            {
                id: 'CancelSurvey:visualdx_difficult_to_use_patients',
                text: 'VisualDx is difficult to use while working with patients.',
            },
            {
                id: 'CancelSurvey:access_through_institution',
                text: 'I can get access through my institution',
            },
            {
                id: 'CancelSurvey:need_more_pricing_options',
                text: 'I need more flexible pricing options.',
            },
            {
                id: 'CancelSurvey:visualdx_does_not_justify_price',
                text: `I don't use VisualDx enough to justify the price.`,
            },
            {
                id: 'CancelSurvey:other',
                text: 'Other',
                require: ['CancelSurvey:description']
            },
        ],
        fields: [
            {
                id: "CancelSurvey:description",
                type: 'textarea', // textarea or textfield
                name: "CancelSurvey:description",
                active: true,
                label: 'Tell us why you are leaving us',
                placeholder: 'Tell us why you are leaving us',
                error: 'A reason why is required',
                fieldName: '"Tell us why you are leaving us"'
            },
        ],
        submit: {
            text: 'Submit'
        }
    },
    subscriptionRetentionOffer: {
        active: true,
        title: 'VisualDx Special Promotion',
        subtitle: `We'd love you to stick around. Take advantage of this reduced rate and keep access.`,
        thirdtitle: `Elite Annual Retention for one year at $99.99 USD`,
        discount: {
            id: '',
            text: ''
        },
        decline: {
            text: `No thanks`,
        },
        accept: {
            text: 'Claim Discount'
        },
        alert: {
            success: {
                text: 'Thanks for staying with us!',
                link: '',
                linkText: '',
            },
            error: {
                text: `Sorry, we could not process your request.`,
                link: 'https://google.com',
                linkText: 'Learn more',
            }
        }
    },
    subscribeSteps: [
        {
            step: [
                {
                    id: 'account',
                    step: 'Account',
                    status: 'active',
                },
                {
                    id: 'Payment',
                    step: 'Payment',
                    status: 'empty',
                },
                {
                    id: 'profile',
                    step: 'Profile',
                    status: 'empty',
                }
            ]
        },
        {
            step: [
                {
                    id: 'account',
                    step: 'Account',
                    status: 'complete',
                },
                {
                    id: 'Payment',
                    step: 'Payment',
                    status: 'active',
                },
                {
                    id: 'profile',
                    step: 'Profile',
                    status: 'empty',
                }
            ]
        },
        {
            step: [
                {
                    id: 'account',
                    step: 'Account',
                    status: 'complete',
                },
                {
                    id: 'payment',
                    step: 'Payment',
                    status: 'active',
                },
                {
                    id: 'profile',
                    step: 'Profile',
                    status: 'empty',
                }
            ]
        },
        {
            step: [
                {
                    id: 'account',
                    step: 'Account',
                    status: 'complete',
                },
                {
                    id: 'payment',
                    step: 'Payment',
                    status: 'complete',
                },
                {
                    id: 'profile',
                    step: 'Profile',
                    status: 'empty',
                }
            ]
        }
    ],
    stepNumberByScreen: {
        subscriptions: 1,
        payment: 1,
        paymentConfirmation: 2,
        paymentSuccess: 3,
    },
    upgradeOnlyProducts,
    specialEventOfferBillPlans,
}


export {
    client,
    appName,
    branding,
    appStrings,
    demoMode,
    devMode,
    styleSettings,
    currencyFormat,
    authTypes,
    customized,
    overrides,
    apiDevModes,
    clientContact,
}