import React from "react";
import { useHistory } from "react-router-dom";
import { Flex, Typography, Grid, GridItem, Stack } from '@strapi/design-system';
import { Card, CardBody, CardContent, CardTitle } from '@strapi/design-system/Card';
import { ToggleCheckbox } from '@strapi/design-system/ToggleCheckbox';
import { Divider } from '@strapi/design-system/Divider';
import useUser from "../../hooks/useUser";
import Strings from "../../constants/Strings";
import { styleSettings } from "../../config";

import useDeviceTypes from "../../hooks/useDeviceTypes";
import useTheme from "../../hooks/useTheme";
import ScreenNames from "../../constants/ScreenNames";

export default function SettingsScreen(props) {

    const history = useHistory();
    const { token } = useUser();
    const { isMobileTablet } = useDeviceTypes();

    const { theme, setTheme, lightTheme, darkTheme  } = useTheme();

    const refreshTheme = () => {
        theme === 'light' ? setTheme('dark') : setTheme('light');
        window.location.reload();
    }

    if(!token) history.push(ScreenNames.SignIn);
    return (
        <Flex alignItems="center" justifyContent="center">
            <Flex alignItems="left" direction="column">
                <Typography variant={styleSettings.pageHeader}>{Strings.settings}</Typography>

                <Grid gap={[5, 2, 1]} style={{ width: isMobileTablet ? '95vw' : 1200, marginTop: 20 }}>
                    <GridItem padding={0} col={4} s={12}>
                        <Stack spacing={3} padding={0}>
                            <Card>
                                <CardBody padding={2}>
                                    <CardTitle variant='beta' children={'Display Mode'}/>
                                </CardBody>
                                <Divider />
                                <CardBody>
                                    <CardContent style={{ width: '50%'}}>
                                        <ToggleCheckbox label="test" onLabel={'Dark'} offLabel={'Light'} checked={theme === 'light' ? false : true} onChange={refreshTheme} />
                                    </CardContent>
                                </CardBody>
                            </Card>                     
                        </Stack>
                    </GridItem>
                </Grid>
            </Flex>
        </Flex>
    )
}