import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { styleSettings } from "../config";
import { useHistory } from "react-router-dom";
import { Tooltip } from '@strapi/design-system/Tooltip';
import { Card, CardBody, CardTitle, CardContent } from '@strapi/design-system/Card';
import { Divider } from '@strapi/design-system/Divider';
import { Tag } from '@strapi/design-system/Tag';
import { TextInput } from '@strapi/design-system/TextInput';
import { Box } from '@strapi/design-system/Box';
import { Button } from '@strapi/design-system/Button';
import Cross from '@strapi/icons/Cross';
import ScreenNames from "../constants/ScreenNames";
import useUser from "../hooks/useUser";

export default function PromoCodeBox(props){

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const { getAffiliate, getAffiliateCampaign, getCampaign, setCampaign, setAffiliateCampaign } = useUser();

    const [promo, setPromo] = useState('');

    const submit = async() => {
        if(!promo) return false;
        history.push(props?.to ? `${props.to}?campaign=${promo}` : `${ScreenNames.PurchaseConfirmation}?campaign=${promo}`);
        setPromo('');        
    }

    useEffect(() => {
        if(!getAffiliate() || (getAffiliate() === process.env.REACT_APP_AFFILIATE) || !getAffiliateCampaign()) setDisabled(false);
    },[]);
 
    return (
        <Card>
            <CardBody>
                <CardContent paddingTop={2} paddingBottom={2}>
                    <CardTitle variant={styleSettings.contentBox.headerVariant}>{'Promo Code'}</CardTitle>
                </CardContent>
            </CardBody>

            <Divider />

            <Box padding={5}>
                <TextInput name='promo' disabled={disabled} aria-label="no" placeholder="Enter promo code" value={promo} onChange={(e) => setPromo(e.target.value)} />
            </Box>            
            {
            (getCampaign() || getAffiliateCampaign()) && 
                <Box
                    paddingLeft={5}
                    paddingRight={5}
                    paddingBottom={5}
                    >
                    <Tooltip description={getCampaign() || getAffiliateCampaign()}>
                        <Tag onClick={() => {
                            setCampaign('');
                            setAffiliateCampaign('');
                            history.push(ScreenNames.Subscriptions);
                        }}
                        icon={<Cross aria-hidden={true} />}>                    
                            {/* If getCampaign() longer than 10 characters, trim campaign code */}
                            {getCampaign().length > 8 ? `${getCampaign().substring(0, 5)}...` : getCampaign()}
                            {getAffiliateCampaign().length > 8 ? `${getAffiliateCampaign().substring(0, 5)}...` : getAffiliateCampaign()}
                        </Tag>
                    </Tooltip>
                </Box>
            }           
            
            <Divider />

            <CardBody>
                <Button children={'Apply'} fullWidth loading={loading} disabled={loading} onClick={submit} />
            </CardBody>            
        </Card>
    )
}