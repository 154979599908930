import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Flex, Typography, Button } from '@strapi/design-system';
import PaymentBox from "../../components/PaymentBox";

import Strings from "../../constants/Strings";
import { customized, styleSettings } from "../../config";
import useUser from "../../hooks/useUser";
import useDeviceTypes from "../../hooks/useDeviceTypes";
import ScreenNames from "../../constants/ScreenNames";
import { StepperOne } from "../../components/custom/Steppers";

export default function CardFailureScreen(props) {

    const history = useHistory(); 
    const { isMobileTablet, isWidth } = useDeviceTypes();
    const { token, cart } = useUser();
    
    return (
        <Flex direction="column">
            {customized.stepperActive && <StepperOne step={customized.stepNumberByScreen.payment} style={{ marginBottom: 50 }} />}
            <Typography variant={styleSettings.pageHeaders.variant}>{'Credit Card Issue'}</Typography>
            {/* <Typography variant={styleSettings.pageSubHeaders.variant} textColor={styleSettings.pageSubHeaders.textColor} style={{ marginTop: 5 }}>{'You have reached the maximum number of attempts for credit card verification. If you have questions please contact our customer support team: Contact | VisualDx'}</Typography> */}
            <Flex alignItems='center' direction="column" style={{ width: isMobileTablet ? '95vw' : 500, marginTop: 30 }}>
                <Typography style={{ textAlign: 'center'}}>
                You have reached the maximum number of attempts for credit card verification. If you have questions please contact our customer support team.
                </Typography>
                <Button onClick={() => window.location.href = 'https://www.visualdx.com/contact/'} size='M' style={{ marginTop: 20 }}>
                    Contact VisualDx
                </Button>
            </Flex>            
        </Flex>
    )
}