import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Flex } from '@strapi/design-system/Flex';
import { Loader } from '@strapi/design-system/Loader';

import useDeviceTypes from "../../hooks/useDeviceTypes";
import { apiAccounts, apiAuth } from "../../services";
import useAPI from "../../hooks/useAPI";
import useUser from "../../hooks/useUser";
import ScreenNames from "../../constants/ScreenNames";

export default function VerifyScreen(props) {

    const history = useHistory(); 
    const { createAccount, signIn } = useAPI();
    const { setAffiliate, setCampaign, setProduct, setAffiliateCampaign, setCart, setUser, setToken, setEntitlement, setRedirectUri, setCategory  } = useUser();
    const { isWidth } = useDeviceTypes();
    const [loading] = useState(true);
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('id');
    const product = queryParams.get('product');
    const entitlement = queryParams.get('entitlement');
    const redirect_uri = queryParams.get('vdx_redirect_uri');
    const campaign = queryParams.get('campaign');
    const affiliate = queryParams.get('affiliate');
    const affiliate_campaign = queryParams.get('affiliate_campaign');
    const category = queryParams.get('category');

    const redirectFailure = () => {
        const product = queryParams.get('product');
        const entitlement = queryParams.get('entitlement');
        const campaign = queryParams.get('campaign');
        const affiliate = queryParams.get('affiliate');
        const redirect_uri = queryParams.get('vdx_redirect_uri');
        const affiliate_campaign = queryParams.get('affiliate_campaign');
        
        let values = {
            product,
            campaign,
            affiliate,
            affiliate_campaign
        };

        let url = redirect_uri || `${process.env.REACT_APP_AUTH_FAILURE_REDIRECT}`;
        if(objectToUrlParams(values)) url = url + `&${objectToUrlParams(values)}`;
        return window.location.href = url;
    };

    const redirect = () => window.location.href = redirect_uri || process.env.REACT_APP_EXT_URL;

    function objectToUrlParams(obj) {
        return Object.entries(obj)
          .filter(([_, value]) => value)
          .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
          .join('&');
    }

    const checkIfAccountExists = async( data ) => {

        //Save Params
        if (product !== 'null' && product !== null && product !== 'undefined' && product) setProduct(product);
        else setProduct('');
        if (redirect_uri !== 'null' && redirect_uri !== null && redirect_uri !== 'undefined' && redirect_uri) setRedirectUri(redirect_uri);
        else setRedirectUri('');
        if (entitlement !== 'null' && entitlement !== null && entitlement !== 'undefined' && entitlement) setEntitlement(entitlement);
        else setEntitlement('');
        if (affiliate !== 'null' && affiliate !== null && affiliate !== 'undefined' && affiliate) setAffiliate(_.toUpper(affiliate));
        else setAffiliate('');
        if (campaign !== 'null' && campaign !== null && campaign !== 'undefined' && campaign) setCampaign(campaign);
        else setCampaign('');
        if (affiliate_campaign !== 'null' && affiliate_campaign !== null && affiliate_campaign !== 'undefined' && affiliate_campaign) setAffiliateCampaign(affiliate_campaign);
        else setAffiliateCampaign('');
        if (category !== 'null' && category !== null && category !== 'undefined' && category) setCategory(category);
        else setCategory('');

        setCart([]);
        setUser({});

        try {
            await apiAccounts.get( data.sub );
            await signIn({ id: data.sub, email: data.email, name: data.name, token });
            return history.push(ScreenNames.Billing);
        } catch (error) {
            //Create account if account does not exist
            if(error?.response?.data?.statusCode === 404) {
                await createAccount({ id: data.sub, email: data.email, name: data.name, token });
                await signIn({ id: data.sub, email: data.email, name: data.name, token });

                let query = ''
                if(category) query = '?category=' + category;

                return history.push(ScreenNames.Subscriptions + query);
            }
        }
    }

    useEffect(() => {
        (async() => {
            if(!token) redirect();
            try {
                // Verify token
                setToken(token);
                const verifyToken = await apiAuth.verify( token );
                // Check if accounts exists
                await checkIfAccountExists( verifyToken.data.data );
            } catch (error) {
                console.log(error)
                redirectFailure();
            }
        })();
    },[]);

    return (
        <Flex direction="column">
            {loading ? <Flex justifyContent='center' alignItems='center' direction="column" style={{ width: isWidth, height: '90vh' }} padding={8}>
                <Loader children='Verifying' />
            </Flex> : null}
        </Flex>
    )
}