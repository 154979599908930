import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Flex } from '@strapi/design-system/Flex';
import useDeviceTypes from "../../hooks/useDeviceTypes";
import useUser from "../../hooks/useUser";
import ScreenNames from "../../constants/ScreenNames";

export default function SignInScreen(props) {

    const history = useHistory();
    const { isWidth } = useDeviceTypes();
    const { token, subscription } = useUser();

    useEffect(() => {
        if(!token) window.location.href = process.env.REACT_APP_EXT_SIGN_IN;
        if(!subscription?.description) history.push(ScreenNames.Subscriptions)
        else history.push(ScreenNames.Billing);
    },[]);
    
    return (
        <Flex direction="column">
            <Flex justifyContent='center' alignItems='center' style={{ width: isWidth }} padding={8}>
                {/* <AuthBox /> */}
            </Flex>            
        </Flex>
    )
}