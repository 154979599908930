import { useState } from 'react';
import _ from 'lodash';
import { demoMode, apiDevModes } from '../config';
import { asyncTimeout } from '../utils/helpers';
import demoProductPackages from '../utils/data/demo/demoProductPackages.json';
import demoSubscription from '../utils/data/demo/demoSubscription.json';
import demoPaymentMethod from '../utils/data/demo/demoPaymentMethod.json';
import demoAccount from '../utils/data/demo/demoAccount.json';
import demoBillingHistory from '../utils/data/demo/demoBillingHistory.json';
import demoCreateAccount from '../utils/data/demo/demoCreateAccount.json';
import demoAccountSignIn from '../utils/data/demo/demoAccountSignIn.json';
import demoForgotPassword from '../utils/data/demo/demoForgotPassword.json';
import { v4 as uuidv4 } from 'uuid';

import { apiAccounts, apiInvoices, apiPaymentMethods, apiProducts, apiSubscriptions, apiTransactions } from '../services';

import { convertInvoices, convertProductPackages, convertSubscriptions, convertTransactions } from '../utils/middleware';

import useUser from './useUser';
import { apiCampaignCode, apiCampaigns, apiCharges, apiGoogle, certCaptureGetToken, certCaptureUpsertCustomer } from '../services/api';

const devMode = process.env.NODE_ENV !== 'production';

export default function useAPI() {

    const { setToken, setUser, logOut, user, setSubscription } = useUser();
    
    const fetchProductPackages = async({ category }) => {
        try {
            if(apiDevModes.fetchProductPackages) {
                await asyncTimeout(2000);
                return demoProductPackages;
            }

            const products = await apiProducts.list();
            if(devMode) console.log({ api: 'fetchProductPackages', products: products.data });
            return convertProductPackages(products.data.data, { category });

        } catch (error) {
            if(devMode) console.log({ error });
            throw error;
        }
    }

    const fetchSubscription = async(body) => {
        try {
            if(apiDevModes.fetchSubscription) {
                await asyncTimeout(2000);
                return demoSubscription;
            }

            const subscriptions = await apiSubscriptions.list(body);
            console.log({ api: 'fetchSubscription.list', subscriptions: subscriptions.data });            

            // const subscription = subscriptions?.data?.data?.data[0];

            // Filter through subscriptions for status of active and Pending Cancel subscriptions
            const subscription = _.filter(subscriptions.data.data.data, i => i.status === 'Active' || i.status === 'Pending Cancel')[0];

            if(subscription?.id) {
                //Get product items
                const items = [];
                for await (const item of subscription?.items?.data) {
                    const res = await apiProducts.get(item.product.vid);
                    items.push(res.data);
                }

                const subscriptionItems = subscription?.items;
                subscription.items = items;
                subscription.subscriptionItems = subscriptionItems;

                console.log({ subscription })

                return convertSubscriptions([subscription])[0];
            } else {
                return {}
            }

            
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const fetchSubscriptions = async(body) => {
        try {
            const subscriptions = await apiSubscriptions.list(body);
            console.log({ api: 'fetchSubscriptions.list', subscriptions: subscriptions.data });
            return subscriptions.data.data.data;            
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const fetchPaymentMethod = async() => {
        try {
            if(apiDevModes.fetchPaymentMethod) {
                await asyncTimeout(1500);
                return demoPaymentMethod;
            }
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const fetchCampaign = async(id) => {
        try {
            const res = await apiCampaigns.get(id);
            if(devMode) console.log({ api: 'fetchCampaign.get', account: res.data.data });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const fetchCampaignCode = async(id) => {
        try {
            const res = await apiCampaignCode.get(id);
            if(devMode) console.log({ api: 'fetchCampaignCode.get', account: res.data.data });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const activateSubscription = async(id) => {
        try {
            const res = await apiSubscriptions.activate(id);
            if(devMode) console.log({ api: 'activateSubscription.post', subscription: res.data.data });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const refreshAccount = async({ accountId }) => {
        try {
            const res = await apiAccounts.refresh({ accountId });
            if(devMode) console.log({ api: 'refreshAccount.get', account: res.data.data });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const certCaptureToken = async(customer_number) => {
        try {
            const res = await certCaptureGetToken.get(customer_number);
            console.log({ api: 'certCaptureToken.get', token_data: res.data.data.response });
            return res.data.data.response;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const certCaptureCustomerUpsert = async(data) => {
        try {
            const res = await certCaptureUpsertCustomer.put(data);
            console.log({ api: 'certCaptureCustomerUpsert.put', customer: res.data.data });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const fetchAccount = async(id) => {
        try {
            if(apiDevModes.fetchAccount) {
                await asyncTimeout(1800);
                return demoAccount;
            }

            const res = await apiAccounts.get(id);
            if(devMode) console.log({ api: 'apiAccounts.get', account: res.data.data });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const updateAccount = async(id, data) => {
        try {
            if(apiDevModes.fetchAccount) {
                await asyncTimeout(1800);
                return demoAccount;
            }

            const res = await apiAccounts.update(id, data);
            if(devMode) console.log({ api: 'apiAccounts.update', account: res.data.data });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const createCharge = async(data) => {
        try {
            const res = await apiCharges.create(data);
            if(devMode) console.log({ api: 'apiCharges.create', charge: res.data.data });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const fetchBillingHistory = async(payload) => {
        try {
            if(apiDevModes.fetchBillingHistory) {
                await asyncTimeout(1900);
                return demoBillingHistory;
            }

            const res = await apiTransactions.list(payload);
            if(devMode) console.log({ api: 'apiTransactions.list', transaction: res.data.data });
            return convertTransactions(res.data.data);
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const cancelSubscription = async(id, dryrun = false) => {
        try {
            if(apiDevModes.cancelSubscription) {
                await asyncTimeout(1900);
                return true;
            }

            const res = await apiSubscriptions.cancel(id, dryrun);
            if(devMode) console.log({ api: 'apiSubscriptions.cancel', subscription: res.data.data });
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }
    const purchaseSubscription = async( payload, dryRun = false ) => {
        try {
            if(apiDevModes.purchaseSubscription) {
                await asyncTimeout(1900);
                return demoSubscription;
            }

            const res = await apiSubscriptions.create( payload, dryRun);
            if(devMode) console.log({ api: 'apiSubscriptions.create', subscription: res.data.data });
            const account = await apiAccounts.get(user.id);
            if(devMode) console.log({ api: 'apiAccounts.get', account: account.data.data });
            setUser(account.data.data);
            return res.data.data;
        } catch (error) {
            console.log({ method: 'useAPI@purchaseSubscription', error });
            throw error;
        }
    }

    const modifySubscription = async( id, payload, dryrun = false, effective_date, bill_prorated_period ) => {
        if(apiDevModes.updateSubscription) {
            await asyncTimeout(1900);
            return demoSubscription;
        }

        const res = await apiSubscriptions.modify( id, payload, dryrun, effective_date, bill_prorated_period );
        if(devMode) console.log({ api: 'apiSubscriptions.modify', subscription: res.data.data });
        const account = await apiAccounts.get(user.id);
        if(devMode) console.log({ api: 'apiAccounts.get', account: account.data.data });        
        setUser(account.data.data);
        return res.data.data;
    }

    const updateSubscription = async( id, payload, dryrun = false ) => {
        if(apiDevModes.updateSubscription) {
            await asyncTimeout(1900);
            return demoSubscription;
        }

        const updatedSubscription = await apiSubscriptions.update( id, payload, dryrun );
        console.log({updatedSubscription})
        const account = await apiAccounts.get(user.id);
        setUser(account.data.data);
        return updatedSubscription.data.data;
    }

    const updatePaymentMethod = async(id, payload) => {
        try {
            if(apiDevModes.updatePaymentMethod) {
                await asyncTimeout(1900);
                return true;
            }
            const res = await apiPaymentMethods.update( id, payload );
            if(devMode) console.log({ api: 'apiPaymentMethods.update', paymentMethod: res.data.data });
            const account = await apiAccounts.get(user.id);
            if(devMode) console.log({ api: 'apiAccounts.get', account: account.data.data });
            setUser(account.data.data);
            return res.data.data;
        } catch (error) {
            console.log({ api: 'updatePaymentMethod', error });
            throw error;
        }
    }

    const signIn = async({ token, id, email, password }) => {
        if(apiDevModes.signIn) {
            setToken(demoAccountSignIn.jwt);
            setUser(demoAccountSignIn.user);
            await asyncTimeout(1900);
            return demoAccountSignIn;
        }

        const res = await apiAccounts.get( id );
        if(devMode) console.log({ api: 'apiAccounts.get', account: res.data.data });

        const subscription = await fetchSubscription({ account: res.data.data.id, status: 'active' });

        setSubscription(subscription);
        //setToken(token);
        setUser(res.data.data);
        
        return { jwt: token, user: res.data.data };
    }

    const createAccount = async({ id, email, name, token, password }) => {
        if(apiDevModes.createAccount) {
            setToken(demoCreateAccount.jwt);
            setUser(demoCreateAccount.user);
            await asyncTimeout(1900);
            return demoCreateAccount;
        }

        const res = await apiAccounts.create({ 
            id: id || _.lowerCase(name), 
            // external_id: id || _.lowerCase(name),
            language: 'en',
            email,
            name
        });
        
        if(devMode) console.log({ api: 'apiAccounts.create', account: res.data.data });

        //setToken(token);
        //setUser(res.data.data);

        return { jwt: token, user: res.data.data };
    }

    const signOut = async(data) => {
        if(apiDevModes.signOut) {
            logOut();
            return true;
        }

        logOut();
        return true;
    }

    const forgotPassword = async(data) => {
        if(apiDevModes.forgotPassword) {
            await asyncTimeout(1900);
            return demoForgotPassword;
        }
    }

    const resetPasswordWithCode = async(data) => {
        try {
            if(apiDevModes.resetPasswordWithCode) {
                await asyncTimeout(1900);
                return true
            }
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }
    const submitCancelSurvey = async(id, payload, dryrun = false) => {
        try {
            if(apiDevModes.submitCancelSurvey) {
                await asyncTimeout(1900);
                return true
            }

            const res = await apiSubscriptions.update(id, payload, dryrun);
            if(devMode) console.log({ api: 'apiSubscriptions.update', subscription: res.data.data });
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }
    const submitRetentionOffer = async( id, payload, dryrun = false ) => {
        try {
            if(apiDevModes.submitRetentionOffer) {
                await asyncTimeout(1500);
                return true
            }

            const res = await apiSubscriptions.modify( id, payload, dryrun );
            if(devMode) console.log({ api: 'apiSubscriptions.modify', subscription: res.data.data });
            return res.data.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }

    const fetchProduct = async(id) => {
        try {
            const res = await apiProducts.get(id);
            if(devMode) console.log({ api: 'apiProducts.get', product: res.data });
            return res.data;
        } catch (error) {
            console.log({ error });
            throw error;
        }
    }


    const googleAddressAutocomplete = async({ types = 'geocode', input }) => {
        try {
            const res = await apiGoogle.placeAutocomplete({ types, input });
            return res.data?.predictions || [];
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    return {
        fetchProductPackages,
        fetchSubscription,
        fetchSubscriptions,
        fetchPaymentMethod,
        fetchAccount,
        updateAccount,
        fetchBillingHistory,
        updateSubscription,
        cancelSubscription,
        updatePaymentMethod,
        signIn,
        signOut,
        createCharge,
        createAccount,
        forgotPassword,
        resetPasswordWithCode,
        purchaseSubscription,
        submitCancelSurvey,
        submitRetentionOffer,
        modifySubscription,
        fetchProduct,
        googleAddressAutocomplete,
        fetchCampaign,
        fetchCampaignCode,
        certCaptureToken,
        certCaptureCustomerUpsert,
        refreshAccount,
        activateSubscription,
    }
}
