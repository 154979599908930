import React, { useEffect, useState } from "react";
import { Flex } from '@strapi/design-system/Flex';

export default function HomeScreen(props) {

    return (
        <Flex alignItems="center" justifyContent="center">
            
        </Flex>
    )
}