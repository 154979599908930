import React, { useEffect } from "react";
import { 
    useHistory
 } from 'react-router-dom';
import { Flex } from '@strapi/design-system/Flex';
import { Typography } from '@strapi/design-system/Typography';
import { branding } from "../config";
import { useState } from "react";
import useAPI from "../hooks/useAPI";
import useUser from "../hooks/useUser";
import Strings from "../constants/Strings";
import ScreenNames from "../constants/ScreenNames";

export default function Header() {

    const history = useHistory();
    const [enable, setEnable] = useState(false);
    const { getUser, redirect_uri } = useUser();
    const [user, setUser] = useState({});

    useEffect(() => {
        // Get the initial data from local storage
        const storedData = localStorage.getItem('user');
        if (storedData) {
            setUser(JSON.parse(storedData));
        }
    
        // Listen for changes in local storage
        const handleStorage = (e) => {
          if (e.key === 'data') {
            setUser(JSON.parse(e.newValue));
          }
        };
        window.addEventListener('storage', handleStorage);
    
        // Cleanup function
        return () => {
          window.removeEventListener('storage', handleStorage);
        };
    }, []);

    useEffect(() => {
        return history.listen((location) => {  
            setUser(getUser());          
            if(location.pathname === '/manage-subscription') setEnable(true)
            else setEnable(false);
        })
    },[history]);

    return <Flex justifyContent='space-between' padding={5} style={{ height: '5vh' }} background="neutral200">
        {enable ? <a href={redirect_uri || process.env.REACT_APP_LOGO_REDIRECT}><img src={branding.logo.light} /></a> : <a ><img src={branding.logo.light} /></a>}
        <Typography variant="pi" style={{ fontSize: 14 }}>{user?.email}</Typography>
    </Flex>;
}