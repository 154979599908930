import React from "react";
import { useHistory } from "react-router-dom";
import { Flex, Typography } from '@strapi/design-system';
import PackagingByFeatures from "../../components/PackagingByFeatures";
import { StepperOne } from "../../components/custom/Steppers";
import useUser from "../../hooks/useUser";
import Strings from "../../constants/Strings";
import { styleSettings, customized } from "../../config";
import ScreenNames from "../../constants/ScreenNames";

export default function SubscriptionsScreen(props) {

    const history = useHistory();
    
    const { token } = useUser();
    if(!token) history.push(ScreenNames.SignIn);
    return (
        <Flex direction="column">
            {customized.stepperActive && <StepperOne step={customized.stepNumberByScreen.subscriptions} style={{ marginBottom: 50 }} />}
            <Typography variant={styleSettings.pageHeader}>{Strings.selectBasePackage}</Typography>
            <PackagingByFeatures
                toSuccess=''
                toError=''
                style={{ marginTop: 35 }} 
            />
        </Flex>
    )
}