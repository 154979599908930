import _ from 'lodash';
const {
  REACT_APP_MONTHLY_NO_TRIAL,
  REACT_APP_MONTHLY,
  REACT_APP_YEARLY_NO_TRIAL,
  REACT_APP_YEARLY,
  REACT_APP_TWO_YEARS_NO_TRIAL,
  REACT_APP_TWO_YEARS,
  REACT_APP_THREE_YEARS_NO_TRIAL,
  REACT_APP_THREE_YEARS
} = process.env;

const BILLING_STATES = {
  GOOD_STANDING: 'Good Standing',
  FREE_TRIAL: 'Free/Trial',
  BILLING_COMPLETED: 'Billing Completed',
  IN_RETRY: 'In Retry',
  UNUSABLE_PAYMENT_METHOD: 'Unusable PaymentMethod',    
  FAILED_TO_COLLECT: 'Failed to Collect',
  OVERDUE: 'Overdue',
  PENDING_ACCEPTANCE: 'Pending Acceptance',
  FREE_PERIOD: 'Free Period',
  TERMINATED: 'Terminated',
  GRACE_PERIOD: 'Grace Period',
  PAUSED: 'Paused',
  PENDING_RESUME: 'Pending Resume',
  UNBILLED: 'Unbilled',
  LEGACY_UNKNOWN_BILLING_STATE: 'Legacy Unknown BillingState',
};

const TERMS = {
  MONTH: 'month',
  YEAR: 'year',
};

const SUBSCRIPTION_STATUSES = {
  ACTIVE: 'Active',
  PENDING_CANCEL: 'Pending Cancel',
  CANCELLED: 'Cancelled',
  DELETED: 'Deleted',
  EXPIRED: 'Expired',
  PENDING_ACTIVATION: 'Pending Activation',
  PENDING_CANCEL: 'Pending Cancel',
  PROCESSING: 'Processing',
  PENDING_PAUSE: 'Pending Pause',
  PAUSED: 'Paused',
};

const {
  MONTH,
  YEAR,
} = TERMS;

const {
  ACTIVE,
  PENDING_CANCEL,
  CANCELLED,
} = SUBSCRIPTION_STATUSES;

// Elligible for free trial
const subscriptionStatusesEligibleForFreeTrial = [
  ACTIVE,
  PENDING_CANCEL,
  // CANCELLED,
  // DELETED,
  // EXPIRED,
  // PENDING_ACTIVATION,
  // PROCESSING,
  // PENDING_PAUSE,
  // PAUSED
];

// Ineligible for free trial
const subscriptionStatusesIneligibleForFreeTrial = [
  ACTIVE,
  PENDING_CANCEL,
  CANCELLED,
  // DELETED,
  // EXPIRED,
  // PENDING_ACTIVATION,
  // PROCESSING,
  // PENDING_PAUSE,
  // PAUSED
];

const {
  GOOD_STANDING,
  FREE_TRIAL,
  BILLING_COMPLETED,
  IN_RETRY,
  UNUSABLE_PAYMENT_METHOD,
  FAILED_TO_COLLECT,
  OVERDUE,
  PENDING_ACCEPTANCE,
  FREE_PERIOD,
  TERMINATED,
  GRACE_PERIOD,
  // PAUSED,
  PENDING_RESUME,
  UNBILLED,
  LEGACY_UNKNOWN_BILLING_STATE
} = BILLING_STATES;

// Elligible for free trial
const billingStatesEligibleForFreeTrial = [

];

// Ineligible for free trial
const billingStatesIneligibleForFreeTrial = [
  FREE_TRIAL,
  BILLING_COMPLETED,
  GOOD_STANDING,
  'Unknown',
  // IN_RETRY,
  // UNUSABLE_PAYMENT_METHOD,
  // FAILED_TO_COLLECT,
  // OVERDUE,
  // PENDING_ACCEPTANCE,
  // FREE_PERIOD,
  // TERMINATED,
  // GRACE_PERIOD,
  // PAUSED,
  // PENDING_RESUME,
  // UNBILLED,
  // LEGACY_UNKNOWN_BILLING_STATE
];

export async function getBillingPlan(data) {
  const { subscriptions, subscription, default_billing_plan: { periods } } = data;
  
  console.log('subscriptions', subscriptions);
  // If subscriptions is empty, then they have no active subscription
  console.log('subscription', subscription);
  console.log('periods', periods.data);

  const period = periods.data[0];
  const term = _.toLower(period.type);
  const quantity = period.quantity;

  let subscriptionStatus = subscription?.response?.status;

  let billingState = subscription?.billingState || subscription?.response?.billingState || 'Unknown';
  
  console.log('billingState', billingState);
  console.log('subscriptionStatus', subscriptionStatus);
  console.log('term', term);
  console.log('quantity', quantity);

  if (_.size(subscriptions) > 0 && !subscription?.id && term === MONTH && quantity === 1) return REACT_APP_MONTHLY_NO_TRIAL;
  if (_.size(subscriptions) > 0 && !subscription?.id && term === YEAR && quantity === 1) return REACT_APP_YEARLY_NO_TRIAL;
  if (_.size(subscriptions) > 0 && !subscription?.id && term === YEAR && quantity === 2) return REACT_APP_TWO_YEARS_NO_TRIAL;
  if (_.size(subscriptions) > 0 && !subscription?.id && term === YEAR && quantity === 3) return REACT_APP_THREE_YEARS_NO_TRIAL;
  
  // Customer has never had a subscription and term in term is year, return free
  if(_.size(subscriptions) === 0 && term === YEAR) return REACT_APP_YEARLY;

  // Customer has never had a subscription and term in term is month, return free
  if(_.size(subscriptions) === 0 && term === MONTH) return REACT_APP_MONTHLY;

  // Customer has a subscription active and in free trial (month)
  if(subscription?.id
    && _.includes(subscriptionStatusesIneligibleForFreeTrial, subscriptionStatus)
    && _.includes(billingStatesIneligibleForFreeTrial, billingState)
    && term === MONTH)
  return REACT_APP_MONTHLY_NO_TRIAL;

  // Customer has a subscription active and in free trial (year)
  if(subscription?.id
    && _.includes(subscriptionStatusesIneligibleForFreeTrial, subscriptionStatus)
    && _.includes(billingStatesIneligibleForFreeTrial, billingState)
    && term === YEAR)
  return REACT_APP_YEARLY_NO_TRIAL;

  // Customer has a subscription active and in free trial (two years)
  if(subscription?.id
    && _.includes(subscriptionStatusesIneligibleForFreeTrial, subscriptionStatus)
    && _.includes(billingStatesIneligibleForFreeTrial, billingState)
    && quantity === 2
    && term === YEAR)
  return REACT_APP_TWO_YEARS_NO_TRIAL;

  // Customer has a subscription active and not free trial (two years)
  if(subscription?.id
    && _.includes(subscriptionStatusesEligibleForFreeTrial, subscriptionStatus)
    && billingState !== FREE_TRIAL
    && quantity === 2
    && term === YEAR)
  return REACT_APP_TWO_YEARS;

  // Customer has a subscription active and in free trial (three years)
  if(subscription?.id
    && _.includes(subscriptionStatusesEligibleForFreeTrial, subscriptionStatus)
    && _.includes(billingStatesIneligibleForFreeTrial, billingState)
    && quantity === 3
    && term === YEAR)
  return REACT_APP_THREE_YEARS_NO_TRIAL;

  // Customer has a subscription active and not free trial (three years)
  if(subscription?.id
    && _.includes(subscriptionStatusesEligibleForFreeTrial, subscriptionStatus)
    && billingState !== FREE_TRIAL
    && quantity === 3
    && term === YEAR)
  return REACT_APP_THREE_YEARS;
}