// import { FontAwesome } from '@expo/vector-icons';
import * as Font from 'expo-font';
import { useEffect, useState } from 'react';
import { useFonts, 
  Montserrat_400Regular, 
  Montserrat_500Medium, 
  Montserrat_600SemiBold,
  Montserrat_700Bold,
  Montserrat_100Thin,
  Montserrat_300Light,
} from '@expo-google-fonts/montserrat';

export default function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = useState(false);

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        // Load fonts
        await Font.loadAsync({
          //...FontAwesome.font,
          Montserrat_400Regular, 
          Montserrat_500Medium, 
          Montserrat_600SemiBold,
          Montserrat_700Bold,
          Montserrat_100Thin,
          Montserrat_300Light,
        });

      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e);
      } finally {
        setLoadingComplete(true);
      }
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}