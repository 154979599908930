import React, { Component } from 'react';
import _ from 'lodash';
import Screens from '../constants/Screens';
import { logErrorService } from '../services/api';

function logErrorToService(url, error, errorInfo, user) {
  const payload = { url, error, errorInfo, user };
  console.log('logErrorToService', payload);
  logErrorService.create(payload)
  .then((res) => console.log(res))
  .catch((err) => console.log(err));
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to a logging service

    // Get the current url
    const url = window.location.href;
    
    console.error(error, errorInfo);

    // Get user info from local storage
    const user = JSON.parse(localStorage.getItem('user'));
    // Get cart info from local storage
    const cart = JSON.parse(localStorage.getItem('cart'));
  
    // Log the error to error reporting service, but if url contains 'localhost' then don't log  
    if (!_.includes(url, 'localhost')) logErrorToService(url, _.toString(error), errorInfo, user, cart);

    // Set the state to trigger a render with an error message
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // Render the error page component
      return <Screens.ErrorScreen />;
    }

    // If no error occurred, render the children components
    return this.props.children;
  }
}

export default ErrorBoundary;