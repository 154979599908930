import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Flex, Typography } from '@strapi/design-system';
import PaymentBox from "../../components/PaymentBox";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";
import Strings from "../../constants/Strings";
import { customized, styleSettings } from "../../config";
import useUser from "../../hooks/useUser";
import useDeviceTypes from "../../hooks/useDeviceTypes";
import ScreenNames from "../../constants/ScreenNames";
import { StepperOne } from "../../components/custom/Steppers";
// import PaymentContainer from '../../containers/billing/PaymentContainer';

export default function PaymentScreen(props) {

    const history = useHistory();
    const { analytics } = useGoogleAnalytics();
    const { isMobileTablet, isWidth } = useDeviceTypes();
    const { token, cart } = useUser();

    useEffect(() => {
        
        /* Track a page view */
        // analytics.page();

    },[]);

    if(!token) history.push(ScreenNames.SignIn);
    if(!cart[0]) history.push(ScreenNames.Subscriptions);
    return (
        <Flex direction="column" >
            {customized.stepperActive && <StepperOne step={customized.stepNumberByScreen.payment} style={{ marginBottom: 50 }} />}
            <Typography variant={styleSettings.pageHeaders.variant}>{Strings.paymentScreen.title}</Typography>
            <Typography variant={styleSettings.pageSubHeaders.variant} textColor={styleSettings.pageSubHeaders.textColor} style={{ marginTop: 5 }}>{Strings.paymentScreen.subtitle}</Typography>
            <Flex style={{ width: isMobileTablet ? '95vw' : 500, marginTop: 30 }}>
                {/* <PaymentContainer /> */}
                <PaymentBox to={ScreenNames.PurchaseConfirmation} />
            </Flex>            
        </Flex>
    )
}