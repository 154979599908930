import React from "react";
import { useHistory } from "react-router-dom";
import { Flex, Typography, Link } from '@strapi/design-system';
import { styleSettings } from "../../config";
import useDeviceTypes from "../../hooks/useDeviceTypes";

export default function MobileSubscriptionRedirectScreen(props) {

    const history = useHistory(); 
    const { isMobileTablet } = useDeviceTypes();

    const handleAppleRedirect = () => {
        const appStoreURL = 'https://apps.apple.com/us/app/visualdx/id348177521';
        window.location.href = appStoreURL;
    };

    const handleGoogleRedirect = () => {
        const googlePlayURL = 'https://play.google.com/store/apps/details?id=com.visualdx.android';
        window.location.href = googlePlayURL;
    };
    
    return (
        <Flex direction="column">
            <Typography variant={styleSettings.pageHeaders.variant} style={{ marginTop: 50 }}>{'Subscription Issue'}</Typography>
            <Flex alignItems='center' direction="column" style={{ width: isMobileTablet ? '95vw' : 500, marginTop: 30 }}>
                <Typography style={{ textAlign: 'center'}}>
                    You need to manage this subscription in <Link onClick={handleGoogleRedirect}>Google Play</Link> / <Link onClick={handleAppleRedirect}>App Store</Link>
                </Typography>
                {/* <Button onClick={() => window.location.href = 'https://www.visualdx.com/contact/'} size='M' style={{ marginTop: 20 }}>
                    Contact VisualDx
                </Button> */}
            </Flex>            
        </Flex>
    )
}