import React, { useState, useImperativeHandle, forwardRef, useRef } from "react";
import { authTypes } from '../config';
import { Typography } from '@strapi/design-system/Typography';
import { TextInput } from '@strapi/design-system/TextInput';
import { Button } from '@strapi/design-system/Button';
import { Status } from '@strapi/design-system/Status';
import { Box } from '@strapi/design-system/Box';
import { Formik, Form } from 'formik';

// import { Form, Link } from '@strapi/helper-plugin';
import * as Yup from 'yup';

import useAPI from "../hooks/useAPI";
import Strings from "../constants/Strings";
import ScreenNames from "../constants/ScreenNames";

const SignInSchema = Yup.object().shape({
    password: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
});
const CreateAccountSchema = Yup.object().shape({
    password: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    name: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
});
const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
});
const CodeSchema = Yup.object().shape({
    code: Yup.string().required('Required'),
    password: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email').required('Required'),
});

const AuthForm = forwardRef((props, ref) => {

    const { signIn,
        createAccount,
        resetPasswordWithCode,
        forgotPassword } = useAPI();


    const [loading, setLoading] = useState(false);
    const [codeVisible, setCodeVisible] = useState(false);
    const [formData, setFormData] = useState({});

    const [showStatus, setStatus] = useState({ active: false, variant: 'danger', body: 'An error occured' });

    const [showCode, setShowCode] = useState(false);
    const [email, setEmail] = useState('');

    const signInRef = useRef();
    const createAccountRef = useRef();
    const forgotPasswordRef = useRef();
    const codeRef = useRef();

    useImperativeHandle(ref, () => ({

    
    }));

    const formSignIn = async(values) => {
        try {            
            setLoading(true);
            const res = await signIn(values);
            setStatus({ ...showStatus, active: false });

            setLoading(false);
            props.onComplete(res);
            
        } catch (error) {
            console.log({ error });
            setStatus({ ...showStatus, active: true});
            setLoading(false);
            props.onError(error);

        }
    }

    const formCreateAccount = async(values) => {
        try {
            setLoading(true);

            const res = await createAccount(values);
            setStatus({ ...showStatus, active: false });

            setLoading(false);
            props.onComplete(res);                
            
        } catch (error) {
            console.log({ error });
            setStatus({ ...showStatus, active: true});
            setLoading(false);
            props.onError(error);

        }
    }

    const formForgotPassword = async() => {
        try {
            setLoading(true);

            const res = await forgotPassword(formData);
            setStatus({ ...showStatus, active: false });

            setEmail(res.email);
            setShowCode(true);

            setLoading(false);
            
        } catch (error) {
            console.log({ error });
            setStatus({ ...showStatus, active: true });
            setLoading(false);
            props.onError(error);

        }
    }

    const formCode = async() => {
        try {
            
            setLoading(true);

            const res = await resetPasswordWithCode(formData);

            setStatus({ ...showStatus, active: false });
            setShowCode(false);
            setEmail('');
            setLoading(false);
            
        } catch (error) {
            console.log({ error });
            setStatus({ ...showStatus, active: true});
            setLoading(false);
            props.onError(error);

        }
    }
     
    return (
        <>
            {showStatus.active && <Status variant={showStatus.variant} style={{ marginBottom: 20 }}>
                <Typography fontWeight="bold">
                    {showStatus.body}
                </Typography>
            </Status>}
            
            {props.type === authTypes.SIGN_IN && <>
                <Formik                
                enableReinitialize
                initialValues={{
                  email: '',
                  password: ''
                }}
                onSubmit={formSignIn}
                validationSchema={SignInSchema}
                validateOnChange={false}
                >
                    {({ values, errors, handleChange, handleSubmit }) => (
                        <Form ref={signInRef} onSubmit={handleSubmit}>
                            <Box padding={0}>
                                <TextInput disabled={loading} name="email" label={Strings.email} error={errors.email} onChange={handleChange} value={values.email} placeholder={Strings.emailAddress} required />
                            </Box>
                            <Box padding={0} style={{ marginTop: 15 }}>
                                <TextInput disabled={loading} name="password" type='password' label={Strings.password} onChange={handleChange} error={errors.password}  value={values.password} placeholder={Strings.password} required />
                            </Box>
                            <Box padding={0} style={{ marginTop: 20 }}>
                                <Button loading={loading} disabled={loading} type='submit' fullWidth children={Strings.signIn} size='L' />
                            </Box>                            
                        </Form>
                    )}
                </Formik>
            </>}

            {props.type === authTypes.CREATE_ACCOUNT && <>
                <Formik                
                enableReinitialize
                initialValues={{
                  email: '',
                  password: '',
                  name: '',
                }}
                onSubmit={formCreateAccount}
                validationSchema={CreateAccountSchema}
                validateOnChange={false}
                >
                    {({ values, errors, handleChange, handleSubmit }) => (
                        <Form ref={createAccountRef} onSubmit={handleSubmit}>
                            <Box padding={0}>
                                <TextInput disabled={loading} name="name" label={Strings.fullName} error={errors.name} onChange={handleChange} value={values.name} placeholder={Strings.name} required />
                            </Box>
                            <Box padding={0} style={{ marginTop: 15 }}>
                                <TextInput disabled={loading} name="email" label={Strings.email} error={errors.email} onChange={handleChange} value={values.email} placeholder={Strings.emailAddress} required />
                            </Box>
                            <Box padding={0} style={{ marginTop: 15 }}>
                                <TextInput disabled={loading} name="password" type='password' label={Strings.password} onChange={handleChange} error={errors.password}  value={values.password} placeholder={Strings.password} required />
                            </Box>
                            <Box padding={0} style={{ marginTop: 20 }}>
                                <Button loading={loading} disabled={loading} type='submit' fullWidth children={Strings.createAccount} size='L' />
                            </Box>                            
                        </Form>
                    )}
                </Formik>
            </>}
            {props.type === authTypes.FORGOT_PASSWORD && <>
                {!showCode ? <Formik                
                enableReinitialize
                initialValues={{
                  email: '',
                }}
                onSubmit={formForgotPassword}
                validationSchema={ForgotPasswordSchema}
                validateOnChange={false}
                >
                    {({ values, errors, handleChange, handleSubmit }) => (
                        <Form ref={forgotPasswordRef} onSubmit={handleSubmit}>
                            <Box padding={0}>
                                <TextInput disabled={loading} name="email" label={Strings.emailAddress} error={errors.email} onChange={handleChange} value={values.email} placeholder={Strings.email} required />
                            </Box>
                            <Box padding={0} style={{ marginTop: 20 }}>
                                <Button loading={loading} disabled={loading} type='submit' fullWidth children={Strings.submit} size='L' />
                            </Box>                            
                        </Form>
                    )}
                </Formik> : <Formik                
                enableReinitialize
                initialValues={{
                  code: '',
                  password: '',
                  email: email,
                }}
                onSubmit={formCode}
                validationSchema={CodeSchema}
                validateOnChange={false}
                >
                    {({ values, errors, handleChange, handleSubmit }) => (
                        <Form ref={codeRef} onSubmit={handleSubmit}>
                            <Box padding={0}>
                                <TextInput disabled={true} name="email" label={Strings.emailAddress} error={errors.email} onChange={handleChange} value={values.email} placeholder={Strings.email} required />
                            </Box>
                            <Box padding={0} style={{ marginTop: 15 }}>
                                <TextInput disabled={loading} name="code" label={Strings.code} error={errors.code} onChange={handleChange} value={values.code} placeholder={Strings.code} required />
                            </Box>
                            <Box padding={0} style={{ marginTop: 15 }}>
                                <TextInput disabled={loading} name="password" type='password' label={Strings.password} error={errors.password} onChange={handleChange} value={values.password} placeholder={Strings.password} required />
                            </Box>
                            <Box padding={0} style={{ marginTop: 20 }}>
                                <Button loading={loading} disabled={loading} type='submit' fullWidth children={Strings.submit} size='L' />
                            </Box>                            
                        </Form>
                    )}
                </Formik>}
            </>}
        </>
    )
});

export default AuthForm;