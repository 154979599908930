import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Flex } from '@strapi/design-system/Flex';
import { Box } from '@strapi/design-system/Box';
import { Typography } from '@strapi/design-system/Typography';
import SubscriptionNowActiveBox from "../../components/SubscriptionNowActive";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";
import Strings from "../../constants/Strings";
import { customized, styleSettings } from "../../config";
import useUser from "../../hooks/useUser";
import useDeviceTypes from "../../hooks/useDeviceTypes";
import { StepperOne } from "../../components/custom/Steppers";
import ScreenNames from "../../constants/ScreenNames";

export default function PurchaseSuccessScreen(props) {

    const history = useHistory();
    const { token } = useUser();
    const { isWidth } = useDeviceTypes();

    // Get state from useLocation
    const location = useLocation();
    const { state } = location;

    useEffect(() => {
        /* Track a page view */
        // analytics.page();
    },[]);

    if(!token) history.push(ScreenNames.SignIn);
    return (
        <Flex direction="column">
            {customized.stepperActive && <StepperOne step={customized.stepNumberByScreen.paymentSuccess} style={{ marginBottom: 50 }} />}
            <Typography variant={styleSettings.pageHeader}>{'Success!'}</Typography>
            <Flex justifyContent='center' alignItems='center' style={{ width: isWidth }} padding={8}>
                <SubscriptionNowActiveBox data={state} />
            </Flex>            
        </Flex>
    )
}