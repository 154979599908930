import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { styleSettings } from "../config";
import { useHistory } from "react-router-dom";
import { Flex } from '@strapi/design-system/Flex';
import { Typography} from '@strapi/design-system/Typography';
import { Card, CardBody, CardSubtitle, CardBadge, CardTitle, CardContent } from '@strapi/design-system/Card';
import { Divider } from '@strapi/design-system/Divider';
import { Loader } from '@strapi/design-system/Loader';
import { Button } from '@strapi/design-system/Button';
import { Link } from '@strapi/design-system/Link';
import Strings from "../constants/Strings";

import useAPI from "../hooks/useAPI";
import useUser from "../hooks/useUser";
import ScreenNames from "../constants/ScreenNames";

export default function AccountBox(props){

    const history = useHistory();

    const { fetchAccount } = useAPI();
    const { user } = useUser();

    const [loading, setLoading] = useState(true);

    const [account, setAccount] = useState({});

    const [loadingError, setLoadingError] = useState(false);

    const fetchApi = async() => {
        try {
            setLoading(true);
            console.log({ user })
            const res = await fetchAccount(user.id);
            console.log({ fethcL: res })
            setAccount(res);
            setLoading(false);
            setLoadingError(false);
        } catch (error) {
            console.log({ error });
            setLoadingError(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        (async() => {
            console.log({user})
            await fetchApi();
        })();
    },[]);    

    return (
        <Card>
            <CardBody>
                <CardContent paddingTop={2} paddingBottom={2}>
                    <CardTitle variant={styleSettings.contentBox.headerVariant}>{Strings.account}</CardTitle>
                </CardContent>
            </CardBody>
            <Divider />
            {loading && 
                <>
                    <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                        <Loader small />
                        <Typography children={`${Strings.loading} ${Strings.account}`} style={{ marginTop: 10 }} />
                    </Flex>
                </>
            }

            {!loading && account?.id && 
                <>                    
                    {account?.name && <CardBody paddingTop={2}>
                        <CardContent>
                            <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{Strings.name}</CardTitle>
                            <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{account.name}</CardSubtitle>
                        </CardContent>
                    </CardBody>}
                    <CardBody>
                        <CardContent>
                            <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{Strings.email}</CardTitle>
                            <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{account.email}</CardSubtitle>
                        </CardContent>
                    </CardBody>
                    <CardBody paddingBottom={2}>
                        <CardContent>
                            <CardTitle>
                                <Link href={process.env.REACT_APP_EXT_RESET_PASSWORD}>{Strings.resetPassword}</Link>
                            </CardTitle>                            
                        </CardContent>
                    </CardBody>
                </>                
            }

            {!loading && !account?.id && !loadingError && 
                <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                    <Typography children={`No ${Strings.account} Available`} style={{ marginTop: 10 }} />
                </Flex>
            }

            {loadingError &&
                <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                    <Typography children={`${Strings.unableToLoad} ${Strings.subscription}`} style={{ marginTop: 10 }} />
                </Flex>
            }               
            
            <Divider />
            <CardBody>
                {!loadingError && <Button size='L' children={`${Strings.manage} ${Strings.account}`} fullWidth disabled={loading} onClick={() => window.location.href = 'https://www.visualdx.com/visualdx/myVisualDx?usePassword'} />}
                {loadingError && <Button size='L' children={Strings.refresh} fullWidth disabled={loading} onClick={fetchApi} />}
            </CardBody>
            
        </Card>
    )
}