import { useState } from 'react';

export default function useUser() {
    const getUser = () => {
        const dataString = localStorage.getItem('user');
        const data = JSON.parse(dataString);
        return data || {};
    };

    const getToken = () => {
        const data = localStorage.getItem('token');
        return data || '';
    };

    const getCart = () => {
        const dataString = localStorage.getItem('cart');
        const data = JSON.parse(dataString);
        return data || [];
    };

    const getCampaign = () => {
        const data = localStorage.getItem('campaign');
        return data || '';
    };

    const getProduct = () => {
        const data = localStorage.getItem('product');
        return data || '';
    };

    const getAffiliate = () => {
        const data = localStorage.getItem('affiliate');
        return data || '';
    };
    
    const getAffiliateCampaign = () => {
        const data = localStorage.getItem('affiliate_campaign');
        return data || '';
    };

    const getSubscription = () => {
        const dataString = localStorage.getItem('subscription');
        const data = JSON.parse(dataString);
        return data || {};
    };
    const getEntitlement = () => {
        const data = localStorage.getItem('entitlement');
        return data || '';
    };
    const getRedirectUri = () => {
        const data = localStorage.getItem('redirect_uri');
        return data || '';
    };
    const getCategory = () => {
        const data = localStorage.getItem('category');
        return data || '';
    };
    
    const [user, setUser] = useState(getUser());
    const [campaign, setCampaign] = useState(getCampaign());
    const [affiliate, setAffiliate] = useState(getAffiliate());
    const [token, setToken] = useState(getToken());
    const [cart, setCart] = useState(getCart());
    const [subscription, setSubscription] = useState(getSubscription());
    const [product, setProduct] = useState(getProduct());
    const [entitlement, setEntitlement] = useState(getEntitlement());
    const [affiliate_campaign, setAffiliateCampaign] = useState(getAffiliateCampaign());
    const [redirect_uri, setRedirectUri] = useState(getRedirectUri());
    const [category, setCategory] = useState(getCategory());

    const saveUser = (data) => {
        localStorage.setItem('user', JSON.stringify(data));
        setUser(data);
    };

    const saveToken = (data) => {
        localStorage.setItem('token', data);
        setToken(data);
    };

    const saveCampaign = (data) => {
        localStorage.setItem('campaign', data);
        setCampaign(data);
    };

    const saveAffiliateCampaign = (data) => {
        localStorage.setItem('affiliate_campaign', data);
        setAffiliateCampaign(data);
    };

    const saveCategory = (data) => {
        localStorage.setItem('category', data);
        setCategory(data);
    };

    const saveAffiliate = (data) => {
        localStorage.setItem('affiliate', data);
        setAffiliate(data);
    };

    const saveProduct = (data) => {
        localStorage.setItem('product', data);
        setProduct(data);
    };

    const saveCart = (data) => {
        localStorage.setItem('cart', JSON.stringify(data));
        setCart(data);
    };

    const saveSubscription = (data) => {
        localStorage.setItem('subscription', JSON.stringify(data));
        setSubscription(data);
    };
    const saveEntitlement = (data) => {
        localStorage.setItem('entitlement', data);
        setEntitlement(data);
    };
    const saveRedirectUri = (data) => {
        const decodedUrl = decodeURIComponent(data);
        localStorage.setItem('redirect_uri', decodedUrl);
        setRedirectUri(data);
    };

    const logOut = (data) => {
        saveToken('');
        saveUser({});
        saveCart([]);
    }

    return {
        setUser: saveUser,
        setToken: saveToken,
        setCart: saveCart,
        setSubscription: saveSubscription,
        setAffiliate: saveAffiliate,
        setCampaign: saveCampaign,
        setProduct: saveProduct,
        setAffiliateCampaign: saveAffiliateCampaign,
        affiliate_campaign,
        product,
        campaign,
        affiliate,
        getProduct,
        getUser,
        getToken,
        getCart,
        getAffiliate,
        getCampaign,
        getAffiliateCampaign,
        logOut,
        user,
        token,
        subscription,
        cart,
        entitlement,
        setEntitlement: saveEntitlement,
        getEntitlement,
        redirect_uri,
        setRedirectUri: saveRedirectUri,
        getRedirectUri,
        setCategory: saveCategory,
        category,
        getCategory,
    }
}
