import React, { useEffect, useState } from "react";
import _ from 'lodash';
import moment from "moment";
import { styleSettings } from "../config";
import { useHistory } from "react-router-dom";
import { Image } from "react-native";
import { Flex } from '@strapi/design-system/Flex';
import { Box } from '@strapi/design-system/Box';
import { Typography} from '@strapi/design-system/Typography';
import { Card, CardBody, CardSubtitle, CardBadge, CardTitle, CardContent, CardHeader } from '@strapi/design-system/Card';
import { Loader } from '@strapi/design-system/Loader';
import { Button } from '@strapi/design-system/Button';
import { Link } from '@strapi/design-system/Link';

import useAPI from "../hooks/useAPI";
import useUser from "../hooks/useUser";
import ScreenNames from "../constants/ScreenNames";

import DDALogo from '../assets/images/payment-icons/dda.svg';
import AmexLogo from '../assets/images/payment-icons/amex.svg';
import VisaLogo from '../assets/images/payment-icons/visa.svg';
import DefaultLogo from '../assets/images/payment-icons/default.svg';
import DiscoverLogo from '../assets/images/payment-icons/discover.svg';
import MastercardLogo from '../assets/images/payment-icons/mastercard.svg';
import { currencyDisplay } from "../utils/helpers";
import Strings from "../constants/Strings";

export default function SubscriptionNowActiveBox(props){ 

    const history = useHistory();
    const successData = props.data;
    console.log({ successData })

    const { user, redirect_uri } = useUser();
    const { fetchSubscription } = useAPI();

    const [loading, setLoading] = useState(false);
    const [subscription, setSubscription] = useState({});

    const getLogo = (paymentMethod) => {        
        const brand = _.toLower(_.get(paymentMethod, 'type'));
        
        if (_.isUndefined(brand)) return DefaultLogo;
            
        switch (brand) {
          case 'amex':
            return AmexLogo;
          case 'discover':
            return DiscoverLogo;
          case 'mastercard':
            return MastercardLogo;
          case 'vi':
            return VisaLogo;
          case 'electroniccheck':
            return DDALogo;
          default:
            return DefaultLogo;
        }
    };

    const fetchData = async() => {
        try {
            //alert(redirect_uri)
            const subscriptionData = await fetchSubscription({ account: user.id, status: 'active' });
            console.log({ subscriptionData });
            setSubscription(subscriptionData);
        } catch (error) {
            console.log(error.response.data)
        }
    }

    useEffect(() => {
        (async() => {
            setLoading(true);
            await fetchData();
            setLoading(false);
        })();
    },[]);

    return (
        <>
            { !loading ? 
                <Card style={{ width: '80%'}}>
                    <CardHeader direction='column' padding={4}>
                        <CardTitle variant='delta'>
                            {'Your VisualDX Subscription is Now Active'}
                        </CardTitle>
                        <CardSubtitle variant='pi' style={{ fontSize: 14, textAlign: 'center', marginTop: 5, width: '100%'}}>
                            {'Your VisualDX Subscription is Now Active. Your subscription will automatically renew using your chosen payment method.'}
                        </CardSubtitle>
                    </CardHeader>
                    <CardBody padding={2}>
                        <CardContent style={{ width: '100%' }} >
                            <Box direction='column' paddingTop={2} paddingBottom={5}>
                                {successData?.plan && <Flex direction='column' alignItems='left' marginBottom={5}>
                                    <Typography variant='beta'>Plan</Typography>
                                    <Typography variant='omega semibold' style={{ marginTop: 5 }}>{successData.plan}</Typography>
                                </Flex>}
                                <Flex direction='column' alignItems='left' marginBottom={5}>
                                    <Typography variant='beta'>Next Bill Date</Typography>
                                    <Typography variant='omega semibold' style={{ marginTop: 5 }}>{_.get(successData, 'nextBillDate', 'TBD')}</Typography>
                                </Flex>
                                <Flex direction='column' alignItems='left' marginBottom={5}>
                                    <Typography variant='beta'>Price</Typography>
                                    <Typography variant='omega semibold' style={{ marginTop: 5 }}>
                                        {
                                            _.get(successData, 'price',
                                            _.get(subscription, 'price', '...'))
                                        }
                                    </Typography>
                                </Flex>
                                {_.get(successData, 'paymentMethod.credit_card.last_digits') && <Flex direction='column' alignItems='left' marginBottom={5}>
                                    <Typography variant='beta'>Credit Card</Typography>
                                    <Typography variant='omega semibold' style={{ marginTop: 5 }}>ending in {successData.paymentMethod.credit_card.last_digits}</Typography>
                                </Flex>}
                                <Flex direction='column' alignItems='left'>
                                    <Typography variant='beta'>Total Charge</Typography>
                                    <Typography variant='omega semibold' style={{ marginTop: 5 }}>{currencyDisplay(successData?.amountChargedToday || 0)}</Typography>
                                </Flex>
                            </Box>
                        </CardContent>
                    </CardBody>
                    <CardBody direction='column' padding={2}>
                        <Button children={'Continue to VisualDx'} size='M' fullWidth onClick={() => window.location.href = redirect_uri || process.env.REACT_APP_SUCCESS_REDIRECT } />
                        <Button variant='secondary' style={{ marginTop: 10 }} children={'Manage Subscription'} size='M' fullWidth onClick={() => history.push(ScreenNames.Billing)} />
                        <Link style={{ marginTop: 10, alignSelf: 'center'}} href={process.env.REACT_APP_TERMS_LINK} target='_blank' children={Strings.termsAndConditions} />
                    </CardBody>
                </Card> : <Loader />
            }
        </>
    )
}