import React, { useEffect, useState } from "react";
import _ from 'lodash';
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { Flex } from '@strapi/design-system/Flex';
import { Typography} from '@strapi/design-system/Typography';
import { Card, CardBody, CardTitle, CardContent } from '@strapi/design-system/Card';
import { Divider } from '@strapi/design-system/Divider';
import { Loader } from '@strapi/design-system/Loader';
import { Button } from '@strapi/design-system/Button';
import { Badge } from '@strapi/design-system/Badge';
import { Tooltip } from '@strapi/design-system/Tooltip';
import { Table, Thead, Tbody, Tr, Td, Th } from '@strapi/design-system/Table';
import { Dots, NextLink, PageLink, Pagination, PreviousLink } from '@strapi/design-system/Pagination';

import Strings from "../constants/Strings";
import { currencyDisplay } from "../utils/helpers";
import { styleSettings } from "../config";

import useUser from "../hooks/useUser";
import useAPI from "../hooks/useAPI";

import ScreenNames from "../constants/ScreenNames";

export default function BillingHistoryBox(props){

    const history = useHistory();

    const { user } = useUser();

    const { fetchBillingHistory } = useAPI();

    const [loading, setLoading] = useState(true);

    const [billingHistory, setBillingHistory] = useState({});

    const [loadingError, setLoadingError] = useState(false);

    const fetchApi = async() => {
        try {
            setLoading(true);
            const res = await fetchBillingHistory({ account: user.id, order: 'desc' });
            console.log(res.data);
            setBillingHistory(res.data);
            setLoading(false);
            setLoadingError(false);
        } catch (error) {
            console.log({ error });
            setLoadingError(true);
            setLoading(false);  
        }
    }

    useEffect(() => {
        (async() => {
            await fetchApi();
        })();
    },[]);    

    return (
        <Card>
            <CardBody>
                <CardContent paddingTop={2} paddingBottom={2}>
                    <CardTitle variant={styleSettings.contentBox.headerVariant}>{Strings.billingHistory}</CardTitle>
                </CardContent>
            </CardBody>
            <Divider />
            {loading && 
                <>
                    <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                        <Loader small />
                        <Typography children={`${Strings.loading} ${Strings.billingHistory}`} style={{ marginTop: 10 }} />
                    </Flex>
                </>
            }

            {!loading && _.size(billingHistory) > 0 && 
                <>                    
                    <CardBody paddingTop={2} paddingBottom={2}>
                        <CardContent style={{ width: '100%' }}>
                            <Table colCount={5} rowCount={_.size(billingHistory)}>
                                <Thead>
                                    <Tr>
                                        <Th>
                                            <Typography variant={styleSettings.table.thText.variant}>{Strings.invoiceId}</Typography>
                                        </Th>
                                        <Th>
                                            <Typography variant={styleSettings.table.thText.variant}>{Strings.subscription}</Typography>
                                        </Th>
                                        <Th>
                                            <Typography variant={styleSettings.table.thText.variant}>{'Bill Date'}</Typography>
                                        </Th>
                                        <Th>
                                            <Typography variant={styleSettings.table.thText.variant}>{Strings.total}</Typography>
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {_.map(billingHistory, i => <Tr key={i.id}>
                                        {/* <Td>
                                            {i.status === 'due' && <Badge paddingLeft={3} paddingRight={3} backgroundColor="warning100" textColor="warning600"children={i.status} />}
                                            {i.status === 'open' && <Badge paddingLeft={3} paddingRight={3} backgroundColor="warning100" textColor="warning600"children={i.status} />}
                                            {i.status === 'late' && <Badge paddingLeft={3} paddingRight={3} backgroundColor="danger100" textColor="danger600" children={i.status} />}
                                            {i.status === 'closed' && <Badge paddingLeft={3} paddingRight={3} backgroundColor="danger100" textColor="danger600" children={i.status} />}
                                            {i.status === 'paid' && <Badge paddingLeft={3} paddingRight={3} backgroundColor="success100" textColor="success600"children={i.status} />}
                                        </Td> */}
                                        <Td>
                                            <Typography ellipsis>{i.id}</Typography>
                                        </Td>
                                        <Td>
                                            <Typography>{i.name}</Typography>
                                        </Td>
                                        <Td>
                                            <Typography>{moment(i.billDate).add(1, 'day').format('MMMM DD, YYYY')}</Typography>
                                        </Td>
                                        <Td>
                                            <Typography>{currencyDisplay(i?.amount)}</Typography>
                                        </Td>
                                    </Tr>)}
                                </Tbody>
                            </Table>
                        </CardContent>
                    </CardBody>
                    <Divider />
                    {/* <CardBody style={{ alignItems: 'center', justifyContent: 'center'}}>
                        <Pagination activePage={1} pageCount={26}>
                            <PreviousLink to="#">Go to previous page</PreviousLink>
                            <PageLink to="#" number={1}>
                                Go to page 1
                            </PageLink>
                            <PageLink to="#" number={2}>
                                Go to page 2
                            </PageLink>
                            <Dots>And 23 other links</Dots>
                            <PageLink to="#" number={25}>
                                Go to page 3
                            </PageLink>
                            <PageLink to="#" number={26}>
                                Go to page 26
                            </PageLink>
                            <NextLink to="#">Go to next page</NextLink>
                        </Pagination>
                    </CardBody> */}
                </>                
            }

            {!loading && _.size(billingHistory) === 0 && !loadingError && 
                <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                    <Typography children={`No ${Strings.billingHistory} Available`} style={{ marginTop: 10 }} />
                </Flex>
            }            

            {loadingError &&
                <>
                    <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                        <Typography children={`${Strings.unableToLoad} ${Strings.billingHistory}`} style={{ marginTop: 10 }} />
                    </Flex>
                    <Divider />
                    <CardBody>
                        <Button children={Strings.refresh} fullWidth disabled={loading} onClick={fetchApi} />
                    </CardBody>
                </>
            }
        </Card>
    )
}