import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { useHistory, useLocation } from "react-router-dom";
import { Box } from '@strapi/design-system/Box';
import { Typography } from '@strapi/design-system/Typography';
import { Flex } from '@strapi/design-system/Flex';
import { Grid, GridItem } from '@strapi/design-system/Grid';
import Strings from "../../constants/Strings";
import { customized } from "../../config";

function StepperOne(props){

    const [steps, setSteps] = useState(customized.subscribeSteps);

    const cols = _.size(customized.subscribeSteps[props.step].step);

    if(customized.stepperType === 'visualdx')
    return <Box borderColor='neutral150' style={{ minWidth: 326, height: 32, borderRadius: 50, ...props.style }}>
        <Grid gridCols={cols} style={{ width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
            {_.map(steps[props.step].step, item => <GridItem key={item.id}>
                <Flex direction="row" justifyContent='center'>
                    {item.status === 'empty' && <Box borderColor='neutral150' style={{ width: 12, height: 12, borderRadius: 12, marginRight: 8 }}></Box>}
                    {item.status === 'active' && <Box style={{ width: 12, height: 12, borderRadius: 12, backgroundColor: '#FAC945', marginRight: 8 }}></Box>}
                    {item.status === 'complete' && <Box style={{ width: 12, height: 12, borderRadius: 12, backgroundColor: '#66BD68', marginRight: 8 }}></Box>}
                    <Typography variant="pi" style={{ fontSize: '12px'}}>{item.step}</Typography>
                </Flex>
            </GridItem>)}
        </Grid>
    </Box>

}

export { StepperOne }