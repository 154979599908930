import React from "react";
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Flex, Typography } from '@strapi/design-system';
import PaymentBox from "../../components/PaymentBox";
import Strings from "../../constants/Strings";
import { styleSettings } from "../../config";
import useUser from "../../hooks/useUser";
import useDeviceTypes from "../../hooks/useDeviceTypes";
import ScreenNames from "../../constants/ScreenNames";
import { Link } from '@strapi/design-system/Link';

export default function AddPaymentScreen(props) {

    const history = useHistory(); 
    const { isMobileTablet, isWidth } = useDeviceTypes();
    const { token, cart } = useUser();

    if(!token) history.push(ScreenNames.SignIn);
    return (
        <Flex direction="column" alignItems='center'>
            <Typography variant={styleSettings.pageHeaders.variant}>{Strings.addPaymentMethod}</Typography>
            <Typography variant={styleSettings.pageSubHeaders.variant} textColor={styleSettings.pageSubHeaders.textColor} style={{ marginTop: 5 }}>{Strings.paymentScreen.subtitle}</Typography>                
            <Flex direction='column' alignItems='center' style={{ width: isMobileTablet ? '95vw' : 500, marginTop: 30 }}>
                {/* <Link to="#" onClick={() => history.goBack()} startIcon={<ArrowLeft />} style={{ marginBottom: 25 }}>
                    GO BACK
                </Link> */}
                <PaymentBox to={ScreenNames.PurchaseConfirmation} />
                <Link to={ScreenNames.PurchaseConfirmation}>
                    Cancel
                </Link>
            </Flex>            
        </Flex>
    )
}