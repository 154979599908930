import React, { useEffect, useState } from "react";
import _, { set } from 'lodash';
import moment from "moment-timezone";
import { styleSettings } from "../config";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Flex } from '@strapi/design-system/Flex';
import { Typography} from '@strapi/design-system/Typography';
import { Accordion, AccordionToggle, AccordionContent } from '@strapi/design-system/Accordion';
import { Card, CardBody, CardSubtitle, CardTitle, CardContent } from '@strapi/design-system/Card';
import { Divider } from '@strapi/design-system/Divider';
import { Loader } from '@strapi/design-system/Loader';
import { Button } from '@strapi/design-system/Button';
import { Box } from '@strapi/design-system/Box';
import { Alert } from '@strapi/design-system/Alert';
import { Link } from '@strapi/design-system/Link';
import { Checkbox } from '@strapi/design-system/Checkbox';
import { Select, Option } from '@strapi/design-system/Select';
import { v4 as uuidv4 } from 'uuid';
import { asyncTimeout } from "../utils/helpers";
import CancelSubscriptionModal from "./CancelSubscriptionModal";
import Strings from "../constants/Strings";
import { currencyDisplay } from "../utils/helpers";
import useUser from "../hooks/useUser";
import useAPI from "../hooks/useAPI";
import ScreenNames from "../constants/ScreenNames";
import { specialEventOfferBillPlan, upgradeProduct } from "../utils/middleware";
import { CardBadge } from "@strapi/design-system";
import { Image } from "react-native";
import useGoogleAnalytics from "../hooks/useGoogleAnalytics";

import DDALogo from '../assets/images/payment-icons/dda.svg';
import AmexLogo from '../assets/images/payment-icons/amex.svg';
import VisaLogo from '../assets/images/payment-icons/visa.svg';
import DefaultLogo from '../assets/images/payment-icons/default.svg';
import DiscoverLogo from '../assets/images/payment-icons/discover.svg';
import MastercardLogo from '../assets/images/payment-icons/mastercard.svg';
import { getBillingPlan } from "../utils/billingPlanLogic";

export default function SubscriptionBox(props){

    const history = useHistory();
    const { analytics } = useGoogleAnalytics();
    const location = useLocation();
    const { cart, user, setSubscription: saveSubscription, affiliate, campaign, setCampaign, getCampaign, setCart, setAffiliateCampaign, setAffiliate, getUser, getAffiliateCampaign, getAffiliate, setEntitlement } = useUser();
    const { fetchSubscription, fetchSubscriptions, purchaseSubscription, modifySubscription, fetchProduct, fetchCampaign, fetchCampaignCode, certCaptureCustomerUpsert, certCaptureToken, fetchAccount, refreshAccount, activateSubscription } = useAPI();
    const queryParams = new URLSearchParams(window.location.search);
    const promo = queryParams.get('campaign');
    const [specialBillPlan, setSpecialBillPlan] = useState({});
    const [showAlert, setShowAlert] = useState({ active: false, variant: 'success', body: '' });
    const [dryRunIssue, setDryRunIssue] = useState(false);
    const [dryRunIssueMessage, setDryRunIssueMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const [showTermsError, setShowTermsError] = useState(false);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
    const [selectedPaymentMethodError, setSelectedPaymentMethodError] = useState(false);
    const [onRetentionOffer, setOnRetentionOffer] = useState(false);
    const [retentionPrice, setRetentionPrice] = useState(0);
    const [retentionPlan, setRetentionPlan] = useState('');
    const [taxExemptionLoading, setTaxExemptionLoading] = useState(false);

    const [subscription, setSubscription] = useState({});
    const [dryRunSubscription, setDryRunSubscription] = useState({});
    const [prevSubscription, setPrevSubscription] = useState({});

    const [loadingError, setLoadingError] = useState(false);
    const [priceInfo, setPriceInfo] = useState({});
    const [freeSubscription, setFreeSubscription] = useState(false);
    const [freeSubscriptionBillingPlan, setFreeSubscriptionBillingPlan] = useState({});

    const reactivateSubscription = async(id) => {
        setLoading(true);
        try {
            const sub = await activateSubscription(id);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const setDefaultPaymentMethod = () => {
        // Check if payment method type is CreditCard, primary and has last digits
        const defaultCard = _.find(getUser()?.payment_methods?.data, i => ((i.type === 'CreditCard') && (i.primary === true) && (i?.credit_card?.last_digits)));
        setSelectedPaymentMethod(defaultCard?.id);
    }

    const fetchApi = async({ isRetention, specialBillPlan }) => {
        try {
            setLoading(true);
            const sub = await fetchSubscription({ account: user.id });
            setPrevSubscription(sub);
            //Check if retention offer
            if(isRetention) {
                setSubscription(sub);
                await retentionDryRun();
            } else {
                if (((props.summary && _.size(cart))) > 0) setSubscription({...cart[0]?.package});
                else setSubscription(sub);
                
                if(props.dryrun) await dryRun(cart[0]?.package, specialBillPlan);

                // console.log('Payment methods >>', getUser()?.payment_methods?.data)
            }
            
            setLoading(false);
            setLoadingError(false);
        } catch (error) {
            // setLoadingError(true);
            //('fetchApi >> ', { error });
            setLoading(false);
        }
    }

    const dryRun = async(workingPackage, specialBillPlan) => {
        const subId = uuidv4();
        try {
            setLoading(true);
            const body = {
                "object": "Subscription",
                "id": uuidv4(),
                "account": {
                   "object": "Account",
                   "id": user.id,
                },
                "billing_plan": {
                   "object": "BillingPlan",
                   "id": workingPackage?.response?.default_billing_plan?.id,
                },
                "payment_method": {
                   "object": "PaymentMethod",
                   "id": user.payment_methods?.data[0]?.id
                },
                "currency": "USD",
                "description": workingPackage.package,
                "items": [
                   {
                        "object": "SubscriptionItem",
                        "id": subId,
                        "product": {
                            "object": "Product",
                            "vid": workingPackage?.response?.vid,
                            
                        },
                   }
                ],
                "policy": {
                    validate_for_future_payment: 1, // turn to 0 for bug testing
                    immediate_auth_failure_policy: "doNotSaveAutoBill"
                }
            }
            
            // Change billing plan if campaign code has an elible plan
            if (workingPackage?.campaign_response?.eligible_billing_plans?.data && workingPackage?.campaign_response?.eligible_billing_plans?.data[0]) {

                body.billing_plan.id = workingPackage?.campaign_response?.eligible_billing_plans?.data[0]?.id;
                console.log('Check working package for campain response', workingPackage);
                // If campaign code is a coupon and price is 0, skip payment method
                // if((_.toLower(workingPackage?.campaign_response?.type) === 'coupon') && (workingPackage?.campaign_response?.percentage_discount === 100)) { 
                //     setFreeSubscription(true);
                //     delete body.payment_method;
                // }
                setFreeSubscriptionBillingPlan(workingPackage?.campaign_response?.eligible_billing_plans?.data[0]);
            } else {
                setFreeSubscription(false);
            }

            if((_.toLower(workingPackage?.campaign_response?.type) === 'coupon') && (workingPackage?.campaign_response?.percentage_discount === 100)) { 
                setFreeSubscription(true);
                delete body.payment_method;
            }
    
            const sub = await fetchSubscription({ account: user.id }); 
            const allSubs = await fetchSubscriptions({ account: user.id });      

            // Billing Plan Logic
            const defaultBillPlan = await getBillingPlan({ subscriptions: allSubs, subscription: sub, default_billing_plan: workingPackage?.response?.default_billing_plan });
            body.billing_plan.id = defaultBillPlan;

            console.log({ defaultBillPlan });
            let upgrade = false;
            if(sub?.id && sub?.response?.status === 'Active') {
                // Check if upgrade
                // console.log('Customers Subscription', sub?.response)
                // console.log('Customers Subscription ID', sub?.id)
                upgrade = upgradeProduct({ ...sub?.response.subscriptionItems.data[0].product }, { ...workingPackage?.response });
               // console.log('Check if upgradable >> ', { upgradable: upgrade, upgradeCheck: [{ vid: sub?.response.subscriptionItems.data[0].product.vid }, { vid: workingPackage?.response?.vid }] })

                delete body.id;
                delete body.payment_method;
                delete body.currency;
                delete body.account;                
                delete body.billing_plan;

                body.billing_plan = {
                    "object": "BillingPlan",
                    "id": workingPackage?.response?.default_billing_plan?.id,
                };

                body.items[0] = {
                    "object": "SubscriptionItem",
                    "id": subId,
                    "product": {
                        "object": "Product",
                        "vid": workingPackage?.response?.vid,
                    },                    
                    "replaces": sub?.response.subscriptionItems.data[0].id
                }

                
            }

            // Remove payment mehtod if no ID is in the payment method object
            if (!body.payment_method?.id) delete body.payment_method;

            //if(getCampaign() || getAffiliateCampaign()) body.items[0].discount_tickets = []; 
            //if(getCampaign()) body.items[0].discount_tickets.push({ object: 'DiscountTicket', campaign_code: getCampaign() });
            if(getCampaign()) body.items[0].campaign_code = getCampaign();
            // if(getAffiliateCampaign()) body.items[0].discount_tickets.push({ object: 'DiscountTicket', campaign_code: getAffiliateCampaign() });
            if(getAffiliate()) body.affiliate = getAffiliate();
            else body.affiliate = process.env.REACT_APP_AFFILIATE;
            if(getAffiliateCampaign()) body.items[0].campaign_code = getAffiliateCampaign();

            // IF sub is active 
            if (sub?.id && sub?.response?.status === 'Active') {
                //Check if billing plan is a trial, if so, change to no trial plan
                const monthlyNoTrial = process.env.REACT_APP_MONTHLY_NO_TRIAL;
                const yearlyNoTrial = process.env.REACT_APP_YEARLY_NO_TRIAL;
                const currentBillPlan = _.toLower(sub.response.billing_plan?.periods?.data[0]?.type);
                const currentBillStatus = sub.response.billing_state;

                if(currentBillStatus !== 'Free/Trial') {
                    // check if annual/monthly 39/49 or annual/monthly 399/499
                    if(currentBillPlan === 'day') {
                        body.billing_plan = {
                            "object": "BillingPlan",
                            "id": monthlyNoTrial,
                        };
                    }
                    if(currentBillPlan === 'month') {
                        body.billing_plan = {
                            "object": "BillingPlan",
                            "id": monthlyNoTrial,
                        };
                    }
                    if(currentBillPlan === 'year') {
                        body.billing_plan = {
                            "object": "BillingPlan",
                            "id": yearlyNoTrial,
                        };
                    }
                    console.log({ billing: body.billing_plan });
                }

                // Check for a campaign code, if exists, apply the eligible billing plan
                let fetchCampaignResponse;
                if (getCampaign()) {
                    const campaignCodeObject = await fetchCampaignCode(getCampaign());
                    if(campaignCodeObject?.campaign?.id) {
                        fetchCampaignResponse = await fetchCampaign(campaignCodeObject.campaign.id);
                        if(fetchCampaignResponse?.eligible_billing_plans?.data[0]?.id) body.billing_plan.id = fetchCampaignResponse.eligible_billing_plans.data[0].id;
                    }
                }

                let res = await modifySubscription(sub?.id, body, true, upgrade ? 'today' : 'nextBill', upgrade);
                let campaign_response = fetchCampaignResponse;
                if(_.last(res.items.data)?.campaign_id) {
                    // campaign_response = await fetchCampaign(_.last(res.items.data)?.campaign_id);
                    res = _.assign({}, res, { campaign_response });
                }
                // console.log('Modify Subscription Dryrun:', res);
                
                let billingObject = res.next_billing;
                if(res.billing_state !== 'Free/Trial') billingObject = res.most_recent_billing ? res.most_recent_billing : res.next_billing;

                const found = _.find(billingObject.items.data, i => i.sku === _.last(res.items.data).product.id);
                //console.log({ subtotal: billingObject.amount, tax_total: 0, total: billingObject.amount, found, billingObject, res})
                if(upgrade) setPriceInfo({ subtotal: billingObject.amount, tax_total: 0, total: billingObject.amount})
                else if (found) setPriceInfo(found);
                else setPriceInfo({ subtotal: billingObject.amount, tax_total: 0, total: billingObject.amount, found, billingObject, res});
                console.log('Modify Dryrun', res);
                setDryRunSubscription(res);
            } else {
                //Special campagin
                if(specialBillPlan?.billPlan) {
                    body.billing_plan = {
                        "object": "BillingPlan",
                        "id": specialBillPlan?.billPlan,
                    };
                }

                body.policy.validate_for_future_payment = 0; // turn off if on trial   

                // Check for a campaign code, if exists, apply the eligible billing plan
                let fetchCampaignResponse;
                if (getCampaign()) {
                    const campaignCodeObject = await fetchCampaignCode(getCampaign());
                    if(campaignCodeObject?.campaign?.id) {
                        fetchCampaignResponse = await fetchCampaign(campaignCodeObject.campaign.id);
                        if(fetchCampaignResponse?.eligible_billing_plans?.data[0]?.id) body.billing_plan.id = fetchCampaignResponse.eligible_billing_plans.data[0].id;
                    }
                }

                // Check for a campaign code, if exists, apply the eligible billing plan
                let res = await purchaseSubscription(body, true);
                let campaign_response = {};
                if(res.items.data[0]?.campaign_id) {
                    campaign_response = await fetchCampaign(res.items.data[0]?.campaign_id);
                    res = _.assign({}, res, { campaign_response });
                }

                // Change billing plan if campaign code has an elible plan
                if(campaign_response?.eligible_billing_plans?.data && campaign_response?.eligible_billing_plans?.data[0]) {

                    // Override billing plan if campaign code has an elible plan
                    body.billing_plan.id = campaign_response?.eligible_billing_plans?.data[0]?.id;

                    if((_.toLower(campaign_response?.type) === 'coupon') && (res.most_recent_billing.amount === 0)) {
                        setFreeSubscription(true);
                    }
                    setFreeSubscriptionBillingPlan(campaign_response?.eligible_billing_plans?.data[0]);
                }

                console.log('Purchase Subscription Dryrun:', res);
                const found = _.find(res.most_recent_billing.items.data, i => i.sku === res.items.data[0].product.id);
                setPriceInfo(found);

                setDryRunSubscription(res);
            }

            setLoading(false);
        } catch (error) {
            setDryRunIssue(true);
            console.log('dryRun >> ', { error });
            setLoading(false);

            switch (error.response.status) {
                case 400:
                    // Check if error message starts with `Cannot modify an AutoBill in 'Stopped' state`
                    if(_.startsWith(error.response?.data?.errorMessage, 'Cannot modify an AutoBill in \'Stopped\' state')) {
                        setDryRunIssueMessage('You have already cancelled your subscription.  Please contact support to reactivate.');
                        setShowAlert({ variant: 'danger', active: true, body: 'You have already cancelled your subscription.  Please contact support to reactivate.' });
                    }
                    else {
                        setShowAlert({ variant: 'danger', active: true, body: error.response?.data?.errorMessage });
                    }
                    await asyncTimeout(10000);
                    setShowAlert(prev => { return {...prev, active: false}});
                    break;
            
                default:
                    setShowAlert({ variant: 'danger', active: true, body: error.response.data.data.message });
                    await asyncTimeout(10000);
                    setShowAlert(prev => { return {...prev, active: false}});
                    break;
            }           
            
        }
    }

    const convertTermText = ({ quantity = 1, type = 'year' }) => {
        if(quantity === 1) {
            return _.toLower(`per ${type}`);
        } else {
            return _.toLower(`every ${quantity} ${type}s`);
        }
    }

    const submit = async({ specialBillPlan }) => {
        if(!freeSubscription && !selectedPaymentMethod) {
            setSelectedPaymentMethodError(true);
            return false;
        }
        setSelectedPaymentMethodError(false);

        if(!agreeToTerms) {
            setShowTermsError(true);
            return false;
        }
        setShowTermsError(false);
        
        console.log('submit >> ', { subscription: subscription.response });
        const subId = uuidv4();
        try {
            setLoading(true);

            if(onRetentionOffer) {
                await submitRetentionOffer();
                setAffiliateCampaign('');
                setCampaign('');
                setCart([]);
                setAffiliate('');
                history.push(ScreenNames.Billing);
            } else {
                const body = {
                    "object": "Subscription",
                    "id": uuidv4(),
                    "account": {
                       "object": "Account",
                       "id": user.id,
                    },
                    "billing_plan": {
                       "object": "BillingPlan",
                       "id": specialBillPlan?.billPlan || subscription?.response?.default_billing_plan?.id,
                    },
                    "payment_method": {
                       "object": "PaymentMethod",
                       "id": selectedPaymentMethod
                    },
                    "currency": "USD",
                    "description": subscription.package,
                    "items": [
                       {
                            "object": "SubscriptionItem",
                            "id": subId,
                            "product": {
                                "object": "Product",
                                "vid": subscription?.response?.vid,                             
                            },
                       }
                    ],
                    "policy": {
                        validate_for_future_payment: 1,
                        immediate_auth_failure_policy: "doNotSaveAutoBill"
                    },
                    "metadata": {
                        "vin:decisionManagerEnabled": "0" 
                    }
                }

                const sub = await fetchSubscription({ account: user.id });
                const allSubs = await fetchSubscriptions({ account: user.id });
                
                // Billing Plan Logic
                const defaultBillPlan = await getBillingPlan({ subscriptions: allSubs, subscription, default_billing_plan: subscription?.response?.default_billing_plan });
                body.billing_plan.id = defaultBillPlan;
                
                if(freeSubscription) {
                    body.policy.validate_for_future_payment = 0; // turn off if on trial
                    body.billing_plan.id = process.env.REACT_APP_FREE_YEAR_BILL_PLAN;
                    delete body.policy;
                }
                
                let upgrade = false;
                if(sub?.id && sub?.response?.status === 'Active') {
                    delete body.id;
                    delete body.payment_method;
                    delete body.currency;
                    delete body.account;
                    delete body.billing_plan;

                    body.billing_plan = {
                        "object": "BillingPlan",
                        "id": subscription?.response?.default_billing_plan?.id,
                    };
                    body.items[0] = {
                        "object": "SubscriptionItem",
                        "id": subId,
                        "product": {
                            "object": "Product",
                            "vid": subscription?.response?.vid,
                        },
                        "replaces": sub?.response?.subscriptionItems.data[0].id
                    }

                    //Check if upgrade
                    upgrade = upgradeProduct({ ...sub?.response.subscriptionItems.data[0].product }, { ...subscription?.response });

                }

                // Remove payment mehtod if no ID is in the payment method object
                if (!body.payment_method?.id) delete body.payment_method;

                //if(getCampaign() || getAffiliateCampaign()) body.items[0].discount_tickets = []; 
                //if(getCampaign()) body.items[0].discount_tickets.push({ object: 'DiscountTicket', campaign_code: getCampaign() });
                if(getCampaign()) body.items[0].campaign_code = getCampaign();
                // if(getAffiliateCampaign()) body.items[0].discount_tickets.push({ object: 'DiscountTicket', campaign_code: getAffiliateCampaign() });
                if(getAffiliate()) body.affiliate = getAffiliate();
                else body.affiliate = process.env.REACT_APP_AFFILIATE;
                if(getAffiliateCampaign()) body.items[0].campaign_code = getAffiliateCampaign();

                //Special campagin
                if(!sub?.id && specialBillPlan?.billPlan) {
                    body.billing_plan = {
                        "object": "BillingPlan",
                        "id": specialBillPlan?.billPlan,
                    };
                }

                 // If subscription should be free, using coupon billing plan and remove payment method
                 if(freeSubscriptionBillingPlan?.id) {
                    body.billing_plan = {
                        object: "BillingPlan",
                        id: freeSubscriptionBillingPlan?.id
                    }

                    delete body.payment_method;
                }

                let newSub = {}
                
                // Check if free product
                if(freeSubscription) {
                    body.billing_plan.id = process.env.REACT_APP_FREE_YEAR_BILL_PLAN;
                    delete body.policy;
                }

                // Check for a campaign code, if exists, apply the eligible billing plan
                let fetchCampaignResponse;
                if (getCampaign()) {
                    const campaignCodeObject = await fetchCampaignCode(getCampaign());
                    if(campaignCodeObject?.campaign?.id) {
                        fetchCampaignResponse = await fetchCampaign(campaignCodeObject.campaign.id);
                        if(fetchCampaignResponse?.eligible_billing_plans?.data[0]?.id) body.billing_plan.id = fetchCampaignResponse.eligible_billing_plans.data[0].id;
                    }
                }
                
                if(sub?.id && sub?.response?.status === 'Active') {
                    newSub = await modifySubscription(sub?.id, body, false, upgrade ? 'today' : 'nextBill', upgrade);
                    // Refresh account entitlements
                    try {
                        await refreshAccount({ accountId: user.id });
                    } catch (error) {
                        console.log('Issue refreshing account', error);
                    }
                }
                else newSub = await purchaseSubscription(body);

                saveSubscription(newSub);
                setAffiliateCampaign('');
                setCampaign('');
                setCart([]);
                setAffiliate('');
                setEntitlement('');

                // Track purchase event
                analytics.track('purchase', {
                    currency: 'USD',
                    value: priceInfo?.sub_total,
                    item_id: dryRunSubscription?.items?.data[0]?.product?.vid,
                    item_name: retentionPlan ? retentionPlan : (subscription?.response?.items ? subscription?.response?.items[0]?.id : subscription?.response?.id),
                    price: dryRunSubscription?.next_billing?.amount,
                    transaction_id: newSub?.id,
                });

                history.push(ScreenNames.PurchaseSuccess, {
                    plan: retentionPlan ? retentionPlan : (subscription?.response?.items ? subscription?.response?.items[0]?.id : subscription?.response?.id),
                    price: currencyDisplay(dryRunSubscription?.next_billing?.amount || 0) + ' ' + convertTermText(dryRunSubscription?.billing_plan?.periods?.data[0] || { quantity: 1, term: 'year' }) + ' + tax',
                    nextBillDate: dryRunSubscription?.next_billing?.created ? moment.utc(dryRunSubscription.next_billing.created).format('MMMM D, YYYY') : moment(subscription.nextBillDate).add(1, 'day').format('MMMM D, YYYY'),
                    paymentMethod: _.find(getUser()?.payment_methods?.data, i => i.id === selectedPaymentMethod),
                    amountChargedToday: priceInfo?.total || priceInfo?.sub_total || 0,
                });
            }

            setLoading(false);
        } catch (error) {
            console.log({ error });
            switch (error?.response?.status) {
                case 400:
                    //setShowAlert({ variant: 'danger', active: true, body: `Promo code '${getCampaign()}' is invalid or expired.  Please try again with another code.` });
                    setShowAlert({ variant: 'danger', active: true, body: error.response?.data?.errorMessage });
                    await asyncTimeout(10000);
                    setShowAlert(prev => { return {...prev, active: false}});
                    break;
            
                default:
                    setShowAlert({ variant: 'danger', active: true, body: error.response.data.data.message });
                    await asyncTimeout(10000);
                    setShowAlert(prev => { return {...prev, active: false}});
                    break;
            }
            setLoading(false);
        }
    }

    const submitRetentionOffer = async() => {
        const subId = uuidv4();
        const sub = await fetchSubscription({ account: user.id });
        const prod = await fetchProduct(location.state.retentionProduct);
        setRetentionPrice(prod.prices.data[0].amount);
        setRetentionPlan(prod.id);

        try {
            setLoading(true);
            const body = {
                "object": "Subscription",
                "id": uuidv4(),
                "billing_plan": {
                   "object": "BillingPlan",
                   "id": location.state.retentionBilling,
                },
                "payment_method": {
                   "object": "PaymentMethod",
                   "id": user.payment_methods?.data[0]?.id
                },
                "items": [
                    {
                        "object": "SubscriptionItem",
                        "id": subId,
                        "product": {
                            "object": "Product",
                            "vid": location.state.retentionProduct,
                        },
                        "campaign_code": process.env.REACT_APP_RETENTION_CAMPAIGN_CODE,
                        "replaces": sub?.response.subscriptionItems.data[0].id
                    }
                ],
                "policy": {
                    validate_for_future_payment: 1,
                    immediate_auth_failure_policy: "doNotSaveAutoBill"
                },
                "metadata": {
                    "vin:decisionManagerEnabled": "0" 
                }
            }

            // if(getCampaign() || getAffiliateCampaign()) body.items[0].discount_tickets = []; 
            // if(getCampaign()) body.items[0].discount_tickets.push({ object: 'DiscountTicket', campaign_code: getCampaign() });
            // if(getAffiliateCampaign()) body.items[0].discount_tickets.push({ object: 'DiscountTicket', campaign_code: getAffiliateCampaign() });
            // if(getAffiliate()) body.affiliate = getAffiliate();

            const res = await modifySubscription(sub?.id, body, false, 'nextBill', false);

            // Refresh account entitlements
            try {
                await refreshAccount({ accountId: user.id });
            } catch (error) {
                console.log('Issue refreshing account', error);
            }
            
            saveSubscription(res);

            /* Track event */
            // analytics.track('subscriptionRetentionOffer', {
            //     object: body.object,
            //     product: body.items[0].product.vid,
            //     billPlan: body.billing_plan.id
            // });

            history.push(ScreenNames.Billing);          

            setLoading(false);
        } catch (error) {
            //console.log({ error });
            setLoading(false);

            switch (error.response.status) {
                case 400:
                    //setShowAlert({ variant: 'danger', active: true, body: `Promo code '${getCampaign()}' is invalid or expired.  Please try again with another code.` });
                    setShowAlert({ variant: 'danger', active: true, body: error.response?.data?.errorMessage });
                    await asyncTimeout(10000);
                    setShowAlert(prev => { return {...prev, active: false}});
                    break;
            
                default:
                    setShowAlert({ variant: 'danger', active: true, body: error.response.data.data.message });
                    await asyncTimeout(10000);
                    setShowAlert(prev => { return {...prev, active: false}});
                    break;
            }           
            
        }
    }

    const retentionDryRun = async() => {
        const subId = uuidv4();
        const sub = await fetchSubscription({ account: user.id });
        const prod = await fetchProduct(location.state.retentionProduct);
        setRetentionPrice(prod.prices.data[0].amount);
        setRetentionPlan(prod.id);

        try {
            setLoading(true);
            const body = {
                "object": "Subscription",
                "id": uuidv4(),
                "billing_plan": {
                   "object": "BillingPlan",
                   "id": location.state.retentionBilling,
                },
                "payment_method": {
                   "object": "PaymentMethod",
                   "id": user.payment_methods?.data[0]?.id
                },
                "items": [
                    {
                        "object": "SubscriptionItem",
                        "id": subId,
                        "product": {
                            "object": "Product",
                            "vid": location.state.retentionProduct,
                        },
                        "campaign_code": process.env.REACT_APP_RETENTION_CAMPAIGN_CODE,
                        "replaces": sub?.response.subscriptionItems.data[0].id
                    }
                ],
                "policy": {
                    validate_for_future_payment: 0, // turn off if free trial
                    immediate_auth_failure_policy: "doNotSaveAutoBill"
                }
            }

            // if(getCampaign() || getAffiliateCampaign()) body.items[0].discount_tickets = []; 
            // if(getCampaign()) body.items[0].discount_tickets.push({ object: 'DiscountTicket', campaign_code: getCampaign() });
            // if(getAffiliateCampaign()) body.items[0].discount_tickets.push({ object: 'DiscountTicket', campaign_code: getAffiliateCampaign() });
            // if(getAffiliate()) body.affiliate = getAffiliate();

            const res = await modifySubscription(sub?.id, body, true, 'nextBill', false);
            console.log('Retention Subscription Dryrun:', res);

            // const found = _.find(res.next_billing.items.data, i => i.sku === _.last(res.items.data).product.id);
            // setPriceInfo(found);

            setPriceInfo({ subtotal: res.next_billing.amount, tax_total: 0, total: res.next_billing.amount})
            setDryRunSubscription(res);
            setLoading(false);
        } catch (error) {
            setDryRunIssue(true);
            console.log({ error });
            setLoading(false);

            switch (error.response.status) {
                case 400:
                    // Check if error message starts with `Cannot modify an AutoBill in 'Stopped' state`
                    if(_.startsWith(error.response?.data?.errorMessage, 'Cannot modify an AutoBill in \'Stopped\' state')) {
                        setDryRunIssueMessage('You have already cancelled your subscription.  Please contact support to reactivate.');
                        setShowAlert({ variant: 'danger', active: true, body: 'You have already cancelled your subscription.  Please contact support to reactivate.' });
                    }
                    else {
                        setShowAlert({ variant: 'danger', active: true, body: error.response?.data?.errorMessage });
                    }
                    await asyncTimeout(10000);
                    setShowAlert(prev => { return {...prev, active: false}});
                    break;
            
                default:
                    setShowAlert({ variant: 'danger', active: true, body: error.response.data.data.message });
                    await asyncTimeout(10000);
                    setShowAlert(prev => { return {...prev, active: false}});
                    break;
            }           
            
        }
    }

    const onTaxExemption = async () => {
        const { REACT_APP_STOREFRONT_ENV } = process.env;
        setTaxExemptionLoading(true);
        try {
            const {
                id,
                email
            } = await fetchAccount(user.id);

            const customer = await certCaptureCustomerUpsert({
                customer_number: id,
                alternative_id: `${REACT_APP_STOREFRONT_ENV === 'dev' ? 'dev_' : ''}${id}`,
                email_address: email,
                country: {
                    id: 1,
                    name: "United States",
                    initials: "US",
                    abbreviation: "USA"
                },
            });
            const token_data = await certCaptureToken(customer.customer_number);
            history.push(ScreenNames.CertCapture, {
                customer,
                token: token_data.token
            })
        } catch (error) {
            console.log(error.response.data.data.error);
            alert(error.response.data.data.error);
        } finally {
            setTaxExemptionLoading(false);
        }
    }

    const getLogo = (paymentMethod) => {
        console.log('getLogo - Input:', { paymentMethod });
        
        const brand = _.toLower(_.get(paymentMethod, 'type'));
        
        if (_.isUndefined(brand)) return DefaultLogo;
    
        console.log('getLogo - "brand":', { paymentMethod, brand });
        
        switch (brand) {
          case 'amex':
            return AmexLogo;
          case 'discover':
            return DiscoverLogo;
          case 'mastercard':
            return MastercardLogo;
          case 'vi':
            return VisaLogo;
          case 'electroniccheck':
            return DDALogo;
          default:
            return DefaultLogo;
        }
    };

    useEffect(() => {
        (async() => {

            //console.log("product >> ", cart[0]?.package?.response?.vid)

            //Check if item in cart and subscription box not on manage subscription screen, return to subscriptions
            if (!cart[0]?.package && (location.pathname !== ScreenNames.Billing) && (location.pathname !== ScreenNames.RetentionOffer)) return history.push(ScreenNames.Subscriptions);
            
            if (promo !== 'null' && promo !== null && promo !== 'undefined' && promo) {
                const specialPlan = specialEventOfferBillPlan({ param: promo, product: cart[0]?.package?.response?.id });
                if(specialPlan) {
                    setCampaign(specialPlan.campaign);
                    setSpecialBillPlan(specialPlan);
                }
                else setCampaign(promo);
            }
            let specialPlan = {}
            if (campaign !== 'null' && campaign !== null && campaign !== 'undefined' && campaign) {
                specialPlan = specialEventOfferBillPlan({ param: campaign, product: cart[0]?.package?.response?.id });
                if(specialPlan) {
                    setSpecialBillPlan(specialPlan);
                }
            }
            // console.log({promo, campaign})
            setDefaultPaymentMethod();
            if(window.location.pathname === ScreenNames.RetentionOffer) {
                setOnRetentionOffer(true);
                if(!location?.state?.retentionProduct || !location?.state?.retentionBilling) return history.push(ScreenNames.Billing);
                await fetchApi({ isRetention: true, specialBillPlan: specialPlan });
            } else {
                await fetchApi({ isRetention: false, specialBillPlan: specialPlan });
            }
        })();
    },[promo]);

    // Track pending purchase details
    useEffect(() => {
        // Track add to cart
        analytics.track('begin_checkout', {
            item_id: dryRunSubscription?.items?.data[0]?.product?.vid,
            item_name: retentionPlan ? retentionPlan : (subscription?.response?.items ? subscription?.response?.items[0]?.id : subscription?.response?.id),
            price: dryRunSubscription?.next_billing?.amount,
        });
    },[]);

    useEffect(() => {
        console.log('subscription >> ', subscription);
        console.log('dryRunSubscription >> ', dryRunSubscription);
    },[subscription, dryRunSubscription]);

    return (
        <>
            {showAlert.active && <Alert closeLabel="Close alert" variant={showAlert.variant} onClose={() => setShowAlert({...showAlert, active: false })} children={showAlert.body} style={{ position: 'absolute', top: 20, right: 20, alignSelf: 'center', justifyContent: 'center' }} />}

            <Card>
                
                <CardBody>
                    <CardContent paddingTop={2} paddingBottom={2}>
                        <CardTitle variant={styleSettings.contentBox.headerVariant}>{props.title || Strings.subscription}</CardTitle>
                    </CardContent>
                </CardBody>
                <Divider />
                {loading && 
                    <>
                        <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                            <Loader small children={`${Strings.loading} ${Strings.subscription}`} />
                            <Typography children={`${Strings.loading} ${Strings.subscription}`} style={{ marginTop: 10 }} />
                        </Flex>
                    </>
                }

                {!loading && subscription?.id && !dryRunIssue ?
                    <>  
                        {/* {props.summary && <CardBody paddingTop={2}>
                            <CardContent>
                                <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{Strings.company}</CardTitle>
                                <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{Strings.clientContact.name}</CardSubtitle>
                                <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{Strings.clientContact.address}</CardSubtitle>
                                <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{Strings.clientContact.email}</CardSubtitle>
                                <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{Strings.clientContact.phone}</CardSubtitle>
                            </CardContent>    
                        </CardBody>} */}
                        <CardBody paddingTop={!props.summary ? 2 : 0}>
                            <CardContent>
                                <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{Strings.plan}</CardTitle>
                                {!onRetentionOffer ?
                                    (
                                        <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>
                                            {subscription?.response?.items ? _.last(subscription?.response?.items)?.id : subscription?.response?.id}
                                        </CardSubtitle>
                                    ) : 
                                    <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{retentionPlan}</CardSubtitle>
                                }
                            </CardContent>
                        </CardBody>
                        {(props.summary && getCampaign()) && <CardBody>
                            <CardContent>
                                <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{Strings.promo_code}</CardTitle>
                                <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{getCampaign()}</CardSubtitle>
                                <Link children={'Remove'} onClick={() => { 
                                    setCampaign(''); 
                                    dryRun(cart[0]?.package, specialBillPlan);
                                    setFreeSubscription(false);
                                    setFreeSubscriptionBillingPlan({});
                                }} />
                            </CardContent>
                        </CardBody>}
                        {props.summary ? <CardBody>                            
                            <CardContent>
                                <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{Strings.price}</CardTitle>
                                {
                                    _.last(dryRunSubscription?.items?.data)?.campaign_id ? (
                                        <Flex direction='column' style={{ alignItems: 'flex-start'}}>
                                                <CardSubtitle variant="pi" style={{ marginTop: 0, wordBreak: 'keep-all', textDecoration: 'line-through', marginRight: 10, textAlign: 'flex-start' }}>
                                                    {`${currencyDisplay(_.last(dryRunSubscription?.items?.data)?.product?.prices?.data[0]?.amount)}`}
                                                </CardSubtitle>

                                                {!onRetentionOffer && <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>
                                                    {`${currencyDisplay(dryRunSubscription?.most_recent_billing?.amount || 0)}`}
                                                </CardSubtitle>}
                                            <Typography
                                            style={{ wordBreak: 'break-word', marginTop: 5, fontSize: 13, fontStyle:'italic', textAlign: 'center' }}
                                            textColor={'primary500'}
                                            variant="pi">
                                                { dryRunSubscription?.campaign_response?.flat_amount_discounts?.data && 
                                                    `$${dryRunSubscription?.campaign_response?.flat_amount_discounts?.data[0]?.amount} off 
                                                    ${((dryRunSubscription?.campaign_response?.number_of_periods === 1) || !dryRunSubscription?.campaign_response?.number_of_periods) ? `for the first ${subscription.term}` : `per ${subscription.term} for the first ${dryRunSubscription?.campaign_response?.number_of_periods} ${subscription.term}s`}. Full price therafter.` 
                                                }

                                                { dryRunSubscription?.campaign_response?.percentage_discount?.data && 
                                                    `${dryRunSubscription?.campaign_response?.percentage_discount?.data[0]?.amount}% off 
                                                    ${((dryRunSubscription?.campaign_response?.number_of_periods === 1) || !dryRunSubscription?.campaign_response?.number_of_periods) ? `for the first ${subscription.term}` : `per ${subscription.term} for the first ${dryRunSubscription?.campaign_response?.number_of_periods} ${subscription.term}s`}. Full price therafter.` 
                                                }
                                            </Typography>
                                        </Flex>
                                    ) : (
                                        <>
                                            {!onRetentionOffer && <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{currencyDisplay(dryRunSubscription?.next_billing?.amount)} {convertTermText(dryRunSubscription?.billing_plan?.periods?.data[0] || { quantity: 1, term: 'year' } )} + tax</CardSubtitle>}
                                        </>
                                    )
                                }
                                {onRetentionOffer && <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{currencyDisplay(dryRunSubscription?.next_billing?.amount)} for one year</CardSubtitle>}
                            </CardContent>
                        </CardBody> : <></>}
                        {dryRunSubscription?.next_billing?.created && <CardBody>
                            <CardContent>
                                <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{Strings.nextBillDate}</CardTitle>
                                {
                                    dryRunSubscription?.next_billing?.created ? (
                                        <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{moment.utc(dryRunSubscription.next_billing.created).format('MMMM D, YYYY')}</CardSubtitle>
                                    ) : (
                                        // <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{moment(subscription.nextBillDate).add(1, 'day').format('MMMM D, YYYY')}</CardSubtitle>
                                        <></>
                                    )
                                } 
                            </CardContent>
                            {subscription?.response?.status === 'Active' && !props.summary && <CardBadge backgroundColor='success500'>{subscription?.response.status}</CardBadge>}
                            {subscription?.response?.status === 'Pending Cancel' && !props.summary && <CardBadge backgroundColor='danger500'>{subscription?.response.status}</CardBadge>}
                        </CardBody>}
                        {subscription?.nextBillDate && <CardBody>
                            <CardContent>
                                <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{Strings.nextBillDate}</CardTitle>
                                <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{moment.utc(subscription?.nextBillDate).format('MMMM D, YYYY')}</CardSubtitle>
                            </CardContent>
                            {/* {subscription?.response?.status === 'Active' && !props.summary && <CardBadge backgroundColor='success500'>{subscription?.response.status}</CardBadge>} */}
                            {/* {subscription?.response?.status === 'Pending Cancel' && !props.summary && <CardBadge backgroundColor='danger500'>{subscription?.response.status}</CardBadge>} */}
                        </CardBody>}

                        {dryRunSubscription?.next_billing?.amount !== 0 && <CardBody>
                            <CardContent>
                                <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{Strings.nextBillAmount}</CardTitle>
                                <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{currencyDisplay(dryRunSubscription?.next_billing?.amount || subscription.price)} + tax</CardSubtitle>
                            </CardContent>
                        </CardBody>}
                        <CardBody>
                            <CardContent>
                                <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{'Tax Exemption'}</CardTitle>
                                <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>
                                    {   
                                        !taxExemptionLoading
                                        ? <Link
                                            onClick={onTaxExemption}
                                            children={'Are you tax exempt? Click here to submit your documentation.'}
                                            style={{
                                                flexWrap: 'wrap',
                                                wordBreak: 'break-word',
                                            }}
                                        />
                                        : <Typography style={{ marginTop: 8 }}>Loading...</Typography>
                                    }
                                </CardSubtitle>
                            </CardContent>
                        </CardBody>
                        
                        {_.size(subscription.addOns) > 0 && <CardBody>
                            <CardContent style={{ width: '100%'}}>
                                <Accordion expanded={expanded} toggle={() => setExpanded(s => !s)} id="acc-1" size="S" variant="secondary">
                                    <AccordionToggle title={`${Strings.addOns} (${_.size(subscription.addOns)})`} />
                                    <AccordionContent padding={3}>
                                        {_.map(subscription.addOns, i => <Box key={i.id} >
                                            <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{i.product}</CardSubtitle>
                                            <CardSubtitle style={{ marginBottom: 10}} variant={'sigma'}>{currencyDisplay(i.prices.price)}</CardSubtitle>
                                        </Box>)}
                                    </AccordionContent>
                                </Accordion>
                            </CardContent>
                        </CardBody>}
                        {props.summary && <CardBody>
                            
                            <Flex alignItems="flex-start" direction='column' style={{ width: '100%' }}>
                                
                                {!freeSubscription && <CardContent style={{ width: '100%' }}>
                                    <Card>
                                        <CardContent padding={3}>
                                            <Select 
                                            label="Select a payment method"
                                            clearLabel="Clear the payment method"
                                            placeholder="Select a payment method"
                                            onClear={() => setSelectedPaymentMethod('')}
                                            onChange={(e) => setSelectedPaymentMethod(e)}
                                            value={selectedPaymentMethod}
                                            error={selectedPaymentMethodError ? 'Please provide a payment method' : ''}
                                            style={{ paddingBottom: 30 }}>
                                                {_.map(getUser()?.payment_methods?.data, i => {
                                                    if (i.type === 'CreditCard')
                                                    return (
                                                        <Option value={i.id}>
                                                            <Flex direction='row'>
                                                                {/* <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 5 }} source={getLogo(i.credit_card)} /> */}
                                                                Card ending in {_.get(i.credit_card, 'last_digits', 'XXXX')}
                                                            </Flex>
                                                        </Option>
                                                    )
                                                })}
                                            </Select>
                                        </CardContent>
                                    </Card>
                                </CardContent>}
                                {(!priceInfo?.subtotal) || (priceInfo?.subtotal !== 0) || (priceInfo?.subtotal !== priceInfo?.total) && <CardContent style={{ width: '100%', marginTop: 10 }}>
                                    <Card>
                                        <CardContent padding={5}>
                                            <Flex justifyContent='space-between'>
                                                <Typography variant='beta' fontWeight="bold">Subtotal</Typography>
                                                <Typography variant='beta'>{currencyDisplay(priceInfo?.subtotal)}</Typography>                                                
                                            </Flex>
                                        </CardContent>
                                    </Card>
                                </CardContent>}
                                {(!priceInfo?.tax_total) || (priceInfo?.tax_total !== 0) && <CardContent style={{ width: '100%', marginTop: 10 }}>
                                    <Card>
                                        <CardContent padding={5}>
                                            <Flex justifyContent='space-between'>
                                                <Typography variant='beta' fontWeight="bold">Tax</Typography>
                                                <Typography variant='beta'>{currencyDisplay(priceInfo?.tax_total || 0)}</Typography>
                                            </Flex>
                                        </CardContent>
                                    </Card>
                                </CardContent>}
                                <CardContent style={{ width: '100%', marginTop: 10 }}>
                                    <Card>
                                        <CardContent padding={5}>
                                            <Flex justifyContent='space-between'>
                                                <Typography variant='beta' fontWeight="bold">Total Due Today</Typography>
                                                <Flex direction='row'>
                                                    <Typography variant='beta'>{currencyDisplay(priceInfo?.total)}</Typography>
                                                    {(priceInfo?.total === priceInfo?.sub_total) > 0 && <Typography style={{ marginLeft: 5 }} variant='pi'>+ tax</Typography>}
                                                    {(priceInfo?.total > priceInfo?.sub_total) > 0 && <Typography style={{ marginLeft: 5 }} variant='pi'>tax included</Typography>}
                                                </Flex>
                                            </Flex>
                                        </CardContent>
                                    </Card>
                                </CardContent>
                            </Flex>
                        </CardBody>}
                        <CardBody paddingBottom={2}>
                            <CardContent>
                                <CardTitle>
                                    {!props.summary && subscription?.response?.status === 'Active' ? <CancelSubscriptionModal subscription={subscription} link dialog/> :
                                    <>
                                        {
                                            !onRetentionOffer && <Link children={`Modify ${Strings.subscription}`} disabled={loading} to={ScreenNames.Subscriptions} />
                                        }
                                    </>}
                                </CardTitle>                            
                            </CardContent>
                        </CardBody>
                    </> :
                    <>
                        <CardBody>
                            {dryRunIssueMessage && !loading && <CardContent>
                                <Typography variant={styleSettings.contentBox.lineHeaderVariant}>{dryRunIssueMessage}</Typography>
                                {/* {prevSubscription?.response?.id && <Button onClick={() => reactivateSubscription(prevSubscription.response.id)} style={{ marginTop: 10 }}>Reactivate</Button>} */}
                            </CardContent>}
                        </CardBody>
                    </>
                }

                {!loading && !subscription?.id && !loadingError &&
                    <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                        <Typography children={`No ${Strings.subscription} Available`} style={{ marginTop: 10 }} />
                    </Flex>
                }

                {loadingError &&
                    <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                        <Typography children={`${Strings.unableToLoad} ${Strings.subscription}`} style={{ marginTop: 10 }} />
                    </Flex>
                }                
                
                <Divider />
                <CardBody>
                    {!loadingError && <>
                        {
                            !props.summary ? <Button {...styleSettings.cards.button} style={{ height: 'auto' }} children={`${Strings.manage} ${Strings.subscription}`} fullWidth disabled={loading} onClick={() => history.push(ScreenNames.Subscriptions)} /> :
                            <Flex alignItems='left' direction='column' paddingTop={2} paddingBottom={2} style={{ width: '100%' }}>
                                
                                <Checkbox style={{ borderRadius: 0 }} name="terms" checked={this} onChange={() => setAgreeToTerms(prev => !prev)} error={showTermsError && 'Agree with the terms'} children={
                                    <Box style={{ marginTop: -3 }}>
                                        <Typography variant="pi" children={'I agree to the '} /> <Link href={process.env.REACT_APP_TERMS_LINK} target='_blank' children={Strings.termsAndConditions} />
                                    </Box>
                                } />  
                                <Flex direction='row' justifyContent='space-between' style={{ marginTop: 20 }}>
                                    <Button {...styleSettings.cards.button} children={(!dryRunSubscription?.most_recent_billing?.amount || dryRunSubscription?.most_recent_billing?.amount === 0) ? Strings.continue : Strings.purchase} fullWidth disabled={loading || dryRunIssue} onClick={() => submit({ specialBillPlan: specialBillPlan })} size='L' style={{ width: 150 }} />
                                    {/* <Button {...styleSettings.cards.button} children={campaign ? (!dryRunSubscription?.most_recent_billing?.amount || dryRunSubscription?.most_recent_billing?.amount === 0 ? Strings.continue : Strings.purchase) : Strings.purchase} fullWidth disabled={loading || dryRunIssue} onClick={() => submit({ specialBillPlan: specialBillPlan })} size='L' style={{ width: 150 }} /> */}
                                    {/* <Flex direction='row'>
                                        <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 5 }} source={VisaLogo} />
                                        <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 5 }} source={AmexLogo} />
                                        <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 5 }} source={DiscoverLogo} />
                                        <Image style={{ width: 50, height: 25, resizeMode: 'contain', marginRight: 5 }} source={MastercardLogo} />
                                    </Flex> */}
                                </Flex>
                            </Flex>
                        }
                    </>}
                    {loadingError && <Button {...styleSettings.cards.button} children={Strings.refresh} fullWidth disabled={loading} onClick={() => fetchApi({ isRetention: onRetentionOffer })} />}
                </CardBody>
                
            </Card>
        </>
    )
}