
import { useState, useEffect } from "react";
import { useWindowDimensions } from 'react-native';
import { styleSettings } from "../config";

export default function useDeviceTypes(){

    const { height, width } = useWindowDimensions();

    const mobile = () => {
        if(width < 500) return true
        else return false;
    }

    const tablet = () => {
        if((width > 500) && (width < 1000)) return true
        else return false;
    }

    const mobileTablet = () => {
        if(width < 1000) return true
        else return false;
    }

    const small = () => {
        if((width > 1000) && (width < 1200)) return true
        else return false;
    }
    const large = () => {
        if(width > 1200) return true
        else return false;
    }

    const containerWidth = () => {
        if(isMobile) return styleSettings.screenSizes.mobile || '95vw';
        if(isTablet) return styleSettings.screenSizes.tablet || '95vw';
        if(isSmallScreen) return styleSettings.screenSizes.small || '90vw';
        if(isLargeScreen) return styleSettings.screenSizes.large || '60vw';
    }
    
    const [ isMobile, setIsMobile] = useState(mobile());

    const [ isTablet, setIsTablet] = useState(tablet());

    const [ isMobileTablet, setIsMobileTablet] = useState(mobileTablet());

    const [ isSmallScreen, setIsSmallScreen] = useState(small());

    const [ isLargeScreen, setIslargeScreen] = useState(large());

    const [ isWidth, setIsWidth] = useState(containerWidth());    

    useEffect(() => {
        setIsMobile(mobile());
        setIsTablet(tablet());
        setIsMobileTablet(mobileTablet());
        setIsSmallScreen(small());
        setIslargeScreen(large());
        setIsWidth(containerWidth());
    },[width]);

    return {
        isMobile,
        isTablet,
        isMobileTablet, 
        isSmallScreen,
        isLargeScreen,
        height,
        width,
        isWidth,
    }

}