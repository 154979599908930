import { useState } from 'react';
import Analytics from 'analytics';
import googleAnalytics from '@analytics/google-analytics';

const app = process.env.REACT_APP_STOREFRONT_ENV === 'prd' ? 'visualdx' : 'visualdx_dev';

// Function to check for the presence of UTM parameters
const hasUTMParameters = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.has('utm_source') || urlParams.has('utm_medium') || urlParams.has('utm_campaign');
};

const analyticsSession = Analytics({
  app,
  plugins: [
    googleAnalytics({
      measurementIds: [process.env.REACT_APP_GOOGLE_ANALYTICS_KEY]
    })
  ]
});

// Conditionally initialize Google Analytics based on UTM parameters
if (process.env.REACT_APP_STOREFRONT_ENV === 'prd' && hasUTMParameters()) {
  analyticsSession.initialize(); // Initialize Google Analytics
}

export default function useGoogleAnalytics() {
  const [analytics] = useState(analyticsSession);

  return {
    analytics
  };
}
