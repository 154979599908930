import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { useHistory, useLocation } from "react-router-dom";
import { Flex, Typography, Box } from '@strapi/design-system';
import Strings from "../../constants/Strings";
import { customized, styleSettings } from "../../config";
import useUser from "../../hooks/useUser";
import useDeviceTypes from "../../hooks/useDeviceTypes";
import ScreenNames from "../../constants/ScreenNames";
import useAPI from "../../hooks/useAPI";
import useScript from "../../hooks/useScript";
import states from '../../utils/states.json';
import useTheme from "../../hooks/useTheme";

export default function CertCaptureScreen(props) {

  const history = useHistory();
  const { theme } = useTheme();
  const { isMobileTablet, isWidth } = useDeviceTypes();
  const { token } = useUser();
  const location = useLocation();
  const stateData = location.state;
  const [GenCert, setGenCert] = useState(null);
  const [certToken, setCertToken] = useState(stateData?.token);
  const [certCustomer, setCertCustomer] = useState(stateData?.customer);
  const [onStateFocus, setOnStateFocus] = useState(false);
  const [shipZone, setShipZone] = useState('');

  useEffect(() => {
    if(!certToken || !certCustomer) return history.goBack();
  },[]);

  useScript(`https://app.certcapture.com/gencert2/js`);

  useEffect(() => {
    setInterval(function() {
      if(window?.GenCert && !GenCert) setGenCert(() => window.GenCert);
    },300);
  },[]);

  const CertificateCapture = () => {
    useEffect(() => {
      if(!shipZone) return false;
      GenCert.init(document.getElementById('form-container'), {
        upload_only: true,
        ship_zone: shipZone,
        token: certToken,
        customer_number: certCustomer.customer_number,
        onUpload: function() {
          alert("Certificate Uploaded!");
          history.goBack();
        },
      });
      //_.set(certCustomer, 'state', 'IN');
      //_.set(certCustomer, 'country', 'United States');
      GenCert.setCustomerData(certCustomer);
      console.log({certCustomer, certToken})
      GenCert.show();
    }, []);
  
    return <Box style={{ width: '100%'}}>
      <div id="form-container"></div>
    </Box>;
  }

  if(!token) history.push(ScreenNames.SignIn);
  return (
    <Flex direction="column" >
      <Typography variant={styleSettings.pageHeaders.variant}>{'Tax Exemption'}</Typography>
      <Typography variant={styleSettings.pageSubHeaders.variant} textColor={styleSettings.pageSubHeaders.textColor} style={{ marginTop: 5 }}>{'Fill out the form below to receive tax exemption.'}</Typography>
      <Flex direction='column' style={{ width: isMobileTablet ? '95vw' : 500, marginTop: 30 }}>
      <Flex alignItems='flex-start' direction='column' style={{ width: '100%'}}>
        <Typography variant="pi" fontWeight="bold" children={'Exposure Zone'} style={{ marginBottom: 4}} />
        <select
            name="vin_billing_address_district"
            onChange={(e) => setShipZone(e.target.value)}
            value={shipZone}
            onFocus={() => setOnStateFocus(true)}
            onBlur={() => setOnStateFocus(false)}
            style={{
              height: 47,
              width: '100%',
              fontFamily: "'Helvetica Neue',Helvetica,Arial,sans-serif",
              fontSize: 16,
              color: theme === 'dark' ? 'white' : 'white',
              padding:"6px 12px",
              lineHeight: 1.42857,
              borderRadius: 8,
              border: onStateFocus ? '3px solid #7CD6F7' : '1px solid #292929',
              background: '#121A27',
              outline: onStateFocus && 0
                
            }}
        >
          <option value={''}>{'Select an exposure zone'}</option>
          {_.map(states, i => <option key={i.abbreviation} value={i.name}>{i.name}</option>)}
        </select>
        <Typography variant="pi" children={'An exposure zone is a location where a certificate can be valid. Exposure zones may indicate a taxing authority or other legal entity to which a certificate may apply.'} style={{ marginTop: 4}} />
        {/* {errors.vin_billing_address_district && <Typography textColor='danger500' variant="pi" children={errors.vin_billing_address_district} style={{ marginTop: 4}} />} */}
      </Flex>

        <Flex direction='column' style={{ marginTop: 25, width: '100%' }}>
          {(certToken && GenCert && shipZone) && <CertificateCapture />}
        </Flex>

      </Flex>
    </Flex>
  )
}