import React, { useEffect, useState } from "react";
import _, { set } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from "react-router-dom";
import { styleSettings } from "../config";
import { Button } from '@strapi/design-system/Button';
import { Box } from '@strapi/design-system/Box';
import { Divider } from '@strapi/design-system/Divider';
import { Badge } from '@strapi/design-system/Badge';
import { Grid, GridItem } from '@strapi/design-system/Grid';
import { Typography } from '@strapi/design-system/Typography';
import { Flex } from '@strapi/design-system/Flex';
import { Checkbox } from '@strapi/design-system/Checkbox';
import { Alert } from '@strapi/design-system/Alert';
import { Loader } from '@strapi/design-system/Loader';
import { Link } from '@strapi/design-system/Link';
import { EmptyStateLayout } from '@strapi/design-system/EmptyStateLayout';
import ArrowLeft from '@strapi/icons/ArrowLeft';
import EmptyDocuments from '@strapi/icons/EmptyDocuments';
import { 
    Card,
    CardBody,
    CardContent
} from '@strapi/design-system/Card';
import { Switch } from '@strapi/design-system/Switch';
import PromoCodeBox from "./PromoCodeBox";
import { currencyDisplay } from "../utils/helpers";
import Strings from "../constants/Strings";
import useDeviceTypes from '../hooks/useDeviceTypes';

import useUser from "../hooks/useUser";
import useAPI from '../hooks/useAPI';
import ScreenNames from "../constants/ScreenNames";
import useGoogleAnalytics from "../hooks/useGoogleAnalytics";
import { getBillingPlan } from "../utils/billingPlanLogic";

export default function PackagingByFeatures(props) {

    const history = useHistory();
    const { analytics } = useGoogleAnalytics();
    const queryParams = new URLSearchParams(window.location.search);
    const campaign = queryParams.get('campaign');
    const category = queryParams.get('category');

    const { setCart, cart, user, getAffiliate, getCampaign, product, setCampaign, getAffiliateCampaign, getProduct, entitlement, setAffiliate, setAffiliateCampaign } = useUser();

    const { isWidth, isMobileTablet } = useDeviceTypes();
    const { fetchProductPackages, fetchSubscriptions, fetchSubscription, purchaseSubscription, fetchCampaign, fetchCampaignCode  } = useAPI();
    const [loading, setLoading] = useState(true);
    const [apiLoading, setApiLoading] = useState(false);
    const [showAlert, setShowAlert] = useState({ active: false, variant: 'success', body: '' });

    const [switchSelected, setSwitchSelected] = useState(false);
    const [packages, setPackages] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState({});
    const [selectedAddOns, setSelectedAddOns] = useState([]);
    const [subscription, setSubscription] = useState({});

    const SelectButton = (props) => <Button size="L" loading={apiLoading} children={apiLoading ? Strings.loading : Strings.continue} onClick={submit} disabled={selectedPackage?.id ? false : true} {...props} />;

    const updateSelectedAddOns = (value) => {
        const found = _.find(selectedAddOns, value);
        if(!found) setSelectedAddOns([...selectedAddOns, value]);
        else setSelectedAddOns(() => {
            return _.filter(selectedAddOns, v => v !== found);
        });
    }

    const submit = async() => {
        if(!selectedPackage) {
            return false;
        }

        const body = {
            package: selectedPackage,
            addOns: selectedAddOns,
            frequency: switchSelected ? 'year' : 'month'
        }
        
        // Track add to cart
        analytics.track('add_product_to_cart', {
            item_id: selectedPackage?.id,
            item_name: selectedPackage?.package,
            price: selectedPackage?.price,
            quantity: 1,
        });

        //If product/subscription dryRun does not have a campaign_id, then remove the campaign_code from storage
        if(!selectedPackage?.dry_response?.items?.data[0]?.campaign_id) setCampaign('');

        setCart([body]);

        // If campaign code is a coupon and price is 0, skip payment method
        if((_.toLower(selectedPackage?.campaign_response?.type) === 'coupon') && ((selectedPackage.price === 0) || (selectedPackage?.campaign_response?.percentage_discount === 100))) { 
            return history.push(ScreenNames.PurchaseConfirmation);
        }

        // If the product is not the same as the selected package id, then remove the campaign_code from storage
        if(product && (selectedPackage?.id !== product)) {
            setCampaign('');
            setAffiliate('');
            setAffiliateCampaign('');
        }

        if(user?.payment_methods?.data[0].vid) history.push(ScreenNames.PurchaseConfirmation)
        else {
            window.location.href = ScreenNames.Payment;
        }

    }

    const convertTermText = ({ quantity, type }) => {
        if(quantity === 1) {
            return _.toLower(`per ${type}`);
        } else {
            return _.toLower(`every ${quantity} ${type}s`);
        }
    }

    const convertTermTextAbbr = ({ quantity = 1, type = 'year' }) => {
        if(_.toLower(type) === 'year') {
            if(quantity === 1)
            return _.toLower(`/yr`);
            else
            return _.toLower(`/${quantity}yrs`);
        } else {
            return _.toLower(`/mo`);
        }
    }

    const renderGridItem = (item) => (
        <GridItem col={4} s={12} xs={12} key={item.id}>
            <Card 
                style={{ cursor: 'pointer' }} 
                onClick={() => setSelectedPackage(item)}
                borderColor={selectedPackage?.id === item.id ? styleSettings.cards.selectedBorderColor : styleSettings.cards.borderColor}
                shadow={selectedPackage?.id && styleSettings.cards.shadow }>
                <CardBody style={{ justifyContent: 'center'}}>                    
                    <CardContent style={{ width: '100%' }}>
                        {item?.campaign_response?.number_of_periods && <Flex justifyContent='right' inline style={{ width: '100%'}}>
                            <Badge style={{ backgroundColor: '#256d3b'}}>
                                {Strings.discountApplied}
                            </Badge>
                        </Flex>}
                        <Box
                            hasRadius 
                            padding={3}
                            style={{ width: '100%'}}
                            //style={{ width: styleSettings.packagingByFeatures.card.width, minHeight: styleSettings.packagingByFeatures.card.minHeight }}
                            children={
                                <Flex direction="column">
                                    <Typography style={{ textAlign: 'center', wordBreak: 'keep-all'}} variant="epsilon">{item.package || item.response.id}</Typography>
                                    {
                                        item?.dry_response?.items?.data[0]?.campaign_id ? (
                                            <Flex direction='column' justifyContent='center'>
                                                <Typography variant="omega" style={{ marginTop: 10, wordBreak: 'keep-all', textDecoration: 'line-through', marginRight: 10 }}>{`${currencyDisplay(item?.dry_response?.items?.data[0]?.product?.prices?.data[0]?.amount)}`}{convertTermTextAbbr(item.response.default_billing_plan.periods.data[0])}</Typography> 
                                                <Typography variant="alpha" style={{ marginTop: 10, wordBreak: 'keep-all' }}>{`${currencyDisplay(item.dry_response?.most_recent_billing?.amount || item.dry_response?.next_billing?.amount || 0)}`}{convertTermTextAbbr(item.response.default_billing_plan.periods.data[0])}</Typography>
                                                {/* <Typography
                                                textColor={'neutral300'}
                                                variant="sigma">
                                                    { ((item?.campaign_response?.number_of_periods === 1) || !item?.campaign_response?.number_of_periods) ? `For the first ${item.term}` : `For the first ${item?.campaign_response?.number_of_periods} ${item.term}s `}
                                                </Typography> */}
                                            </Flex>
                                        ) : (
                                            <>
                                                <Typography variant="alpha" style={{ marginTop: 10, wordBreak: 'keep-all' }}>{`${currencyDisplay(item.price)}`}{convertTermTextAbbr(item.response.default_billing_plan.periods.data[0])}</Typography>
                                                {/* {_.toLower(item.term) === 'day' && <Typography textColor={'neutral300'} variant="sigma">{`per month`}</Typography>}
                                                {_.toLower(item.term) === 'month' && <Typography textColor={'neutral300'} variant="sigma">{`per month`}</Typography>}
                                                {_.toLower(item.term) === 'year' && <Typography textColor={'neutral300'} variant="sigma">{`per year`}</Typography>} */}
                                                {/* <Typography textColor={'neutral300'} variant="sigma">{convertTermText(item.response.default_billing_plan.periods.data[0])}</Typography> */}
                                            </>
                                        )
                                    }
                                    
                                    {item?.campaign_response?.number_of_periods && 
                                    <>
                                        <Typography
                                        textColor={'primary600'}
                                        style={{ wordBreak: 'break-word', marginTop: 5, fontSize: 15, fontStyle:'italic', textAlign: 'center' }}
                                        variant="pi">
                                            { item?.campaign_response?.flat_amount_discounts?.data && 
                                                `$${item?.campaign_response?.flat_amount_discounts?.data[0]?.amount} off 
                                                ${((item?.campaign_response?.number_of_periods === 1) || !item?.campaign_response?.number_of_periods) ? `for the first ${item.term}` : `per ${item.term} for the first ${item?.campaign_response?.number_of_periods} ${item.term}s`}. Full price thereafter.` 
                                            }

                                            { item?.campaign_response?.percentage_discount?.data && 
                                                `${item?.campaign_response?.percentage_discount?.data[0]?.amount}% off 
                                                ${((item?.campaign_response?.number_of_periods === 1) || !item?.campaign_response?.number_of_periods) ? `for the first ${item.term}` : `per ${item.term} for the first ${item?.campaign_response?.number_of_periods} ${item.term}s`}. Full price thereafter.` 
                                            }
                                        </Typography>
                                        {/* <Typography
                                        textColor={'primary500'}
                                        style={{ wordBreak: 'break-word', marginTop: 5, fontSize: 13, fontStyle:'italic', textAlign: 'center' }}
                                        variant="pi">
                                            Full price thereafter.
                                        </Typography> */}
                                    </>
                                    }
                                    
                                    <Divider style={{ width: '100%', marginTop: 30, marginBottom: -10 }} />
                                    
                                    <Flex direction="column" alignItems={'left'} style={{ wordBreak: 'keep-all', width: '100%'}}>
                                        <Typography variant="beta" textColor="primary600" style={{ marginTop: 35, color: '#FAE48E' }}>{Strings.packagingFeatures}</Typography>
                                        {_.map(item.products, products => <Flex key={'product_'+products.id} direction="column" alignItems="left" style={{ wordBreak: 'keep-all'}}>
                                            <Typography key={'product_id_' + products.id} variant="omega" style={{ marginTop: 20 }}>{products.product}</Typography>
                                            <Typography key={'description_' + products.id} variant="pi" textColor={'neutral600'} style={{ textAlign: 'left'}}>{products.description}</Typography>
                                        </Flex>)}
                                        
                                        {_.size(item.addOns) > 0 && <Typography variant="beta" textColor="primary600" style={{ marginTop: 30 }}>{Strings.packagingAddOnOptions}</Typography>}

                                        {_.map(item.addOns, addOn => <Box style={{ marginTop: 20 }} key={'addon_'+addOn.id}>
                                            <Checkbox key={addOn.id} 
                                            checked={selectedPackage?.id === item.id && _.find(selectedAddOns, addOn)} 
                                            disabled={item.id=== selectedPackage?.id ? false : true} 
                                            onChange={() => updateSelectedAddOns(addOn)} 
                                            children={<Typography textColor={'neutral300'} variant="omega">{addOn.product} - {currencyDisplay(addOn.prices.price)}/{addOn.prices.frequency}</Typography>} 
                                            // hint={addOn.description}
                                            />
                                        </Box>)}                                                
                                    </Flex>
                                </Flex>
                            }
                        />
                    </CardContent>
                </CardBody>
            </Card>  
        </GridItem>
    );

    const getAffiliateCode = () => getAffiliate() ? getAffiliate() : _.get(process.env, 'REACT_APP_AFFILIATE');
    
    const getCampaignCode = () => {
        const affiliateCampaignCode = getAffiliateCampaign() ? getAffiliateCampaign() : null;

        if(!_.isNull(affiliateCampaignCode)) return affiliateCampaignCode;

        const campaignCode = getCampaign() ? getCampaign() : null;

        if(!_.isNull(campaignCode)) return campaignCode;

        return '';
    };

    const getCampaignId = (dryRunResponse) => _.get(dryRunResponse, 'items.data.0.campaign_id');

    const hasCampaign = (dryRunResponse) => _.isUndefined(getCampaignId(dryRunResponse)) === false;

    const getDryRunPromise = async (fetchedPackage, allSubs, _subscription) => {
        const handleDryRunError = (dryRunError, output) => {
            console.log('Error with dry run while fetching price.', dryRunError );
            
            const errorMessage = _.get(dryRunError, 'response.data.errorMessage', '');
    
            switch (dryRunError.response.status) {
                case 400:
                    if(errorMessage.includes('Failed to redeem code')) {
                        setShowAlert({ variant: 'danger', active: true, body: `Promo code is invalid or expired.  Please try again with another code.` });                    
                    }
                    if(errorMessage.includes('Subscription create failed: Campaign code')) {
                        setShowAlert({ variant: 'danger', active: true, body: `Campaign code is invalid or expired.  Please try again with another code.` });  
                        setCampaign('');
                    }
                    if(errorMessage.includes('Subscription create failed: Campaign redemption error')){
                        console.log('Ignoring error: for product display' );
                        return Promise.resolve(output);
                    }
                    break;
            
                default:
                    setShowAlert({ variant: 'danger', active: true, body: dryRunError.response.data.data.message });
                    break;
            }
    
            return Promise.resolve(output);
        };
    
        const handleFetchCampaignError = (fetchCampaignError, output) => {
            console.log('Error with dry run while fetching campaign details.', fetchCampaignError );
    
            return Promise.resolve(output);
        };

        const affiliate = getAffiliateCode();
        const campaign_code = getCampaignCode();
        const account = { object: 'Account', id: _.get(user, 'id')};
        const product = { object: 'Product', vid: _.get(fetchedPackage, 'response.vid')};
        const subscriptionItem = { object: 'SubscriptionItem', id: uuidv4(), product, campaign_code };
        const billing_plan = { object: 'BillingPlan', id: _.get(fetchedPackage, 'response.default_billing_plan.id')};        
        
        const body = { object: 'Subscription', currency: 'USD', id: uuidv4(), account, affiliate, billing_plan, items: [subscriptionItem]};
        
        // Billing Plan Logic
        const defaultBillPlan = await getBillingPlan({ subscriptions: allSubs, subscription: _subscription, default_billing_plan: _.get(fetchedPackage, 'response.default_billing_plan') });
        // console.log({ defaultBillPlan, campaign_code: getCampaignCode(), campaign: getCampaign() });
        
        // body.billing_plan.id = defaultBillPlan;

        // Check for a campaign code, if exists, apply the eligible billing plan
        let fetchCampaignResponse;
        if (campaign_code) {
            const campaignCodeObject = await fetchCampaignCode(campaign_code).catch((e) => handleFetchCampaignError(e, fetchedPackage));
            if(campaignCodeObject?.campaign?.id) {
                fetchCampaignResponse = await fetchCampaign(campaignCodeObject.campaign.id).catch((e) => handleFetchCampaignError(e, fetchedPackage));
                if(fetchCampaignResponse?.eligible_billing_plans?.data[0]?.id) body.billing_plan.id = fetchCampaignResponse.eligible_billing_plans.data[0].id;
            }
        }
        else body.billing_plan.id = defaultBillPlan;

        const dryRunResponse = await purchaseSubscription(body, true).catch((e) => handleDryRunError(e, fetchedPackage));
        
        const newPackage = { 
            ...fetchedPackage,
            dry_response: dryRunResponse,
            campaign_code: getCampaignCode(), 
            price: _.get(dryRunResponse, 'price', _.get(dryRunResponse, 'next_billing.amount'))
        };

        if(hasCampaign(dryRunResponse)){ 
            // const fetchCampaignResponse = await fetchCampaign(getCampaignId(dryRunResponse)).catch((e) => handleFetchCampaignError(e, newPackage));

            _.set(newPackage, 'campaign_response', fetchCampaignResponse);

            console.log({ dryRunResult: newPackage, hasCampaign: true });

            return newPackage;
        }

        console.log({ dryRunResult: newPackage, hasCampaign: false });
        
        return newPackage;
    };

    const [disabledSubscription, setDisabledSubscription] = useState('');

    // If no product is selected, select the product with the hightest rank (response.metadata.rank), if rank is null, set rank to 100, if terms is 'year' ignore 'month' products and vice versa, Lowest number is the highest rank
    const selectHighestRankedProduct = (term) => {
        let highestRanked = _.minBy(packages, i => i.response.metadata.rank);
        if(!highestRanked) highestRanked = _.minBy(packages, i => i.response.metadata.rank = 100);
        if(term) highestRanked = _.find(packages, i => i.term === term);
        setSelectedPackage(highestRanked);
    }
    useEffect(() => {
        if(switchSelected) selectHighestRankedProduct('year');
        else selectHighestRankedProduct('month');
    },[switchSelected]);

    // Move selected product to the top of the list
    // useEffect(() => {
    //     if(selectedPackage?.id && !_.isEmpty(packages)) {
    //         let found = _.find(packages, i => i.id === selectedPackage.id);
    //         let filtered = _.filter(packages, i => i.id !== selectedPackage.id);
    //         setPackages([found, ...filtered]);
    //     }
    // },[selectedPackage]);

    // Track selected product
    useEffect(() => {
        analytics.track('select_product', {
            item_id: selectedPackage?.id,
            item_name: selectedPackage?.package,
            price: selectedPackage?.price,
        });
    },[selectedPackage]);

    const fetchApi = async() => {
        try {
            setLoading(true);
            const fetchedSubscription = await fetchSubscription({ account: user.id, status: 'active' });
            console.log({ fetchedSubscription })
            const allSubs = await fetchSubscriptions({ account: user.id });

            let fetchedPackages = await fetchProductPackages({ category });
            console.log({ fetchedPackages })

            //Filter for entitlement and bill plan, this filter is used to only get products with bill plans/products of the same term.
            let filtered = [];
            // let term = _.toLower(fetchedSubscription?.response?.billing_plan?.periods?.data[0]?.type);
            // let filteredTerms = _.filter(fetchedPackages, item => item.term === term);
            // if(_.size(filteredTerms) > 0 ) fetchedPackages = filteredTerms;

            _.map(fetchedPackages, item => {
                const found = _.find(item.response.entitlements.data, ent => ent.id === entitlement);
                if(found) filtered.push(item);
            });

            if(_.size(filtered) > 0 ) fetchedPackages = filtered;
            // console.log({ fetchedSubscription, fetchedPackages })
            
            if (fetchedSubscription?.id) {
                setSubscription(fetchedSubscription);
                setSelectedPackage({ id: fetchedSubscription?.id }); 
                setDisabledSubscription(fetchedSubscription?.id);
            }
            setShowAlert(prev => { return {...prev, active: false}});

            const dryRunPromises = _.reduce(fetchedPackages, (promises, fetchedPackage) => [ ...promises, getDryRunPromise(fetchedPackage, allSubs, fetchedSubscription)], []);
            try{
                const dryRunPromiseResults = await Promise.allSettled(dryRunPromises);
                const newPackages = _.reduce(dryRunPromiseResults, (items, item) => [ ...items, item.value ], []);
                
                // Ranking Testing
                console.log('Ranking Testing ...');
                _.map(newPackages, i => {
                    console.log(`Product: ${i.package} - Rank: ${i.response.metadata.rank} - Term: ${i.term}`);
                })
    
                setPackages(newPackages);
                setLoading(false);
                
                let found;
                if(product) {
                    found = _.find(newPackages, i => i.id === product);
                    setSelectedPackage(found);
                }
    
                if(!getProduct()) setSwitchSelected(true);
                else if(found?.term === 'year') setSwitchSelected(true);
                else if (found?.term === 'month') setSwitchSelected(false);
                else setSwitchSelected(true);
    
                if(cart[0]?.package) setSelectedPackage(cart[0]?.package);
    
                // If only one product available, auto select it
                if(_.size(newPackages) === 1) setSelectedPackage(newPackages[0]);

                // If no product is selected, select the product with the hightest rank
                if (!product) selectHighestRankedProduct(found?.term || 'year');

            } catch(error){
                console.log('ERROR', { error });
            }
        } catch (error) {
            console.log({ method: 'fetchApi', error });
            setLoading(false);
        }
    }

    useEffect(() => {
        (async() => {
            if (campaign !== 'null' && campaign !== null && campaign !== 'undefined' && campaign) setCampaign(campaign);
            await fetchApi();
        })();
    },[]);

    useEffect(() => {
        return history.listen((location) => {            
            const updatedQueryParams = new URLSearchParams(location.search);            
            const updatedCampaign = updatedQueryParams.get('campaign');
            if(updatedCampaign) {
                //console.log(`You changed the campaign code to: `, updatedCampaign);
                setCampaign(updatedCampaign);
            }
            fetchApi();
        })
    },[history]);

    useEffect(() => {
        setSelectedAddOns([]);
    },[selectedPackage]);

    const disableSwitch = (packages) => {
        if (_.size(packages) === 0) return true;
        if (_.size(packages) > 0) return false;
        
        // if (_.some(packages, i => i.term !== 'year')) return true;
        // if (_.some(packages, i => i.term !== 'month')) return true;
    }

    const hideSwitch = (packages) => {
        if (_.some(packages, i => i.term === 'year') && _.some(packages, i => i.term === 'month')) return false;
        else return true;
    }

    return (
        <>
            {showAlert.active && <Alert closeLabel="Close alert" variant={showAlert.variant} onClose={() => setShowAlert({...showAlert, active: false })} children={showAlert.body} style={{ position: 'absolute', top: 20, right: 20, alignSelf: 'center', justifyContent: 'center' }} />}

            <Flex style={{ width: isMobileTablet ? '100%' : '80%' }} alignItems='center' justifyContent='center'>
                {!loading ? <Flex direction="column" style={{ width: '100%', ...props.style }}>
                    {!_.isEmpty(subscription) && <Flex paddingBottom={5} alignItems={'flex-start'} style={{ width: '100%' }}>
                        <Link to={ScreenNames.Billing} startIcon={<ArrowLeft />}>
                            Manage Subscription
                        </Link>
                    </Flex>}
                    {_.size(packages) > 0 && <Card padding={4} style={{ width: '100%', marginBottom: 20 }} borderColor={styleSettings.cards.borderColor} shadow={styleSettings.cards.shadow}>
                        <CardContent>
                            <CardBody style={{ alignItems: 'center' }}>
                                <Flex justifyContent='space-between' width='100%' direction={isMobileTablet ? 'column' : 'row'} >
                                    {hideSwitch(packages) ? <Box />  : <Switch label="Monthly or Yearly" disabled={disableSwitch(packages)} selected={switchSelected} onLabel={Strings.annualSubscription} offLabel={Strings.monthlySubscription} onChange={() => setSwitchSelected(s => !s)} visibleLabels /> }                   
                                    <SelectButton style={{ marginTop: isMobileTablet && 15 }} />
                                </Flex>
                            </CardBody>
                        </CardContent>
                    </Card>}
                    <Box style={{ width: '100%'}}>
                        {_.size(packages) > 0 && <Grid 
                            gap={5} 
                            //gridCols={3}
                            direction={isMobileTablet ? 'column' : 'row'} 
                            style={{ width: '100%' }} >
                                <GridItem col={3} s={12}>
                                    <PromoCodeBox to={ScreenNames.Subscriptions} />
                                </GridItem>
                                <GridItem col={9} s={12}>
                                    <Grid gap={5}>
                                    {_.map(packages, item =>
                                        <>
                                            { !switchSelected && item.term !== 'year' && renderGridItem(item) }
                                            { switchSelected && item.term === 'year' && renderGridItem(item) }
                                        </>
                                    )}
                                    </Grid>
                                </GridItem>                        
                        </Grid>}
                        
                        {_.size(packages) === 0 && (
                            <Flex alignItems='center' justifyContent='center'>
                                <Box padding={5} style={{ width: isWidth }}>
                                    <EmptyStateLayout style={{ width: '100%'}} icon={<EmptyDocuments width="10rem" />} content={Strings.noDataAvailable} />
                                </Box>
                            </Flex>
                        )}
                    </Box>
                </Flex> :
                <Loader style={{ marginTop: 300 }}>{'Loading Content'}</Loader>}
                
            </Flex>
        </>
    )
}