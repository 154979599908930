import React, { useState, useRef } from "react";
import _ from "lodash";
import { useHistory, Router } from "react-router-dom";
import { authTypes, styleSettings } from '../config';
import { ModalLayout, ModalBody, ModalHeader } from '@strapi/design-system/ModalLayout';
import { Typography } from '@strapi/design-system/Typography';
import { Button } from '@strapi/design-system/Button';
import { Link } from '@strapi/design-system/Link';
import { Box } from '@strapi/design-system/Box';
import { Divider } from '@strapi/design-system/Divider';
import { TabGroup, Tabs, Tab, TabPanels, TabPanel } from '@strapi/design-system/Tabs';
import { SimpleMenu, MenuItem } from '@strapi/design-system/SimpleMenu';

import AuthForm from "./AuthForm";

import { asyncTimeout, scrollTop } from "../utils/helpers";

import useAPI from "../hooks/useAPI";
import useUser from "../hooks/useUser";
import useDeviceTypes from "../hooks/useDeviceTypes";

import Strings from "../constants/Strings";
import ScreenNames from "../constants/ScreenNames";

const tabs = [
    {
        id: 0,
        text: Strings.signIn
    },
    {
        id: 1,
        text: Strings.createAccount
    },
    {
        id: 2,
        text: Strings.forgotPassword
    },
]

export default function AuthModal(props) {

    const history = useHistory();

    const { isMobileTablet } = useDeviceTypes();

    const { token: tokenData, logOut, getToken, user } = useUser();

    const [loading, setLoading] = useState(false);
    //const [isVisible, setIsVisible] = useState(props.isVisible);
    const [link] = useState(props.link);
    const [token, setToken] = useState(tokenData);
    const [menuValue, setMenuValue] = useState('');
    const [isVisible, setIsVisible] = useState(false);

    const [initialSelectedTabIndex, setInitialSelectedTabIndex] = useState(0);
    const [selectedTab, setSelectedtab] = useState(initialSelectedTabIndex);

    //Form
    const [formLoading, setFormLoading] = useState(false);
    const authFormRef = useRef();

    const refreshForToken = () => setToken(() => getToken());

    useState(() => {
        (async() => {
            refreshForToken();
        })();
    },[]);

    return <>

        { token ?
            <>
                {/* {link ? <Link to='#' onClick={() => { logOut(); refreshForToken(); }} children={Strings.signOut} /> : 
                <Button disabled={props.disabled} fullWidth={props.fullWidth} onClick={() => { logOut(); refreshForToken(); }} children={Strings.signOut} /> } */}
                <SimpleMenu id="menu" label={<Typography children={user.name} />}>
                    <MenuItem id="my-account" to={ScreenNames.Billing}>
                    {Strings.myAccount}
                    </MenuItem>
                    <MenuItem id="settings" to={ScreenNames.Settings} style={{ marginTop: 7 }}>
                    {Strings.settings}
                    </MenuItem>
                    <MenuItem id="sign-out" onClick={() => { logOut(); refreshForToken(); history.push(ScreenNames.SignIn) }} style={{ marginTop: 7 }}>
                    {Strings.signOut}
                    </MenuItem>
                </SimpleMenu>
            </> :
            <>
                {link ? <Link to='#' onClick={() => history.push(ScreenNames.SignIn)} children={Strings.signIn} /> : 
                <Button disabled={props.disabled} fullWidth={props.fullWidth} onClick={() => history.push(ScreenNames.SignIn)} children={Strings.signIn} /> }
            </>
        }
        
        {isVisible && 
        <ModalLayout style={{ width: isMobileTablet ? '95vw' : 500 }} onClose={() => setIsVisible(prev => !prev)}>
            <ModalHeader>
                <Typography fontWeight="bold" textColor="neutral800" as="h2">
                    {tabs[selectedTab].text}
                </Typography>                
            </ModalHeader>
            <ModalBody background={styleSettings.modals.body.background} padding={0}>
                <TabGroup label="Some stuff for the label" id="tabs" variant="simple" initialSelectedTabIndex={0} onTabChange={setSelectedtab}>
                    <Tabs>
                        {_.map(tabs, tab => <Tab disabled={loading} style={{ width: !isMobileTablet && '33.33%' }} key={tab.id}>{tab.text}</Tab>)}
                    </Tabs>
                    <Divider />
                    <TabPanels>
                        <TabPanel>
                            <Box padding={7}>
                                <AuthForm
                                    type={authTypes.SIGN_IN}
                                    onError={(error) => { 
                                        setLoading(false);
                                        console.log({error}); 
                                    }} 
                                    onComplete={(res) => {
                                        refreshForToken();
                                        setLoading(false); 
                                        setIsVisible(false); 
                                        scrollTop();
                                        history.push(ScreenNames.Billing)
                                    }}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel>
                            <Box padding={7}>
                                <AuthForm
                                    type={'create_account'}
                                    onError={(error) => { 
                                        console.log({error}); 
                                    }} 
                                    onComplete={(res) => { 
                                        refreshForToken();
                                        setLoading(false); 
                                        setIsVisible(false); 
                                        scrollTop();
                                        history.push(ScreenNames.Subscriptions);
                                    }}
                                />
                            </Box>
                        </TabPanel>
                        <TabPanel>
                            <Box padding={7}>
                                <AuthForm
                                    type={'forgot_password'}
                                    onError={(error) => { 
                                        console.log({error}); 
                                    }} 
                                    onComplete={(res) => { 
                                        refreshForToken();
                                        setLoading(false); 
                                        setIsVisible(false); 
                                        scrollTop();
                                    }}
                                />
                            </Box>
                        </TabPanel>
                    </TabPanels>
                </TabGroup>                
            </ModalBody>
        </ModalLayout>}
    </>;
}