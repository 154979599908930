import React, { useEffect, useState } from "react";
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Flex } from '@strapi/design-system/Flex';
import { Typography} from '@strapi/design-system/Typography';
import { Card, CardBody, CardSubtitle, CardTitle, CardContent } from '@strapi/design-system/Card';
import { Divider } from '@strapi/design-system/Divider';
import { Loader } from '@strapi/design-system/Loader';
import { Button } from '@strapi/design-system/Button';
import { Link } from '@strapi/design-system/Link';
import { Box } from '@strapi/design-system/Box';

import PaymentMethodModal from "./PaymentMethodModal";

import useUser from "../hooks/useUser";
import useDeviceTypes from "../hooks/useDeviceTypes";
import Strings from "../constants/Strings";
import { styleSettings } from "../config";

import useAPI from "../hooks/useAPI";
import ScreenNames from "../constants/ScreenNames";

export default function PaymentMethodBox(props){
    const history = useHistory();
    const { isMobileTablet } = useDeviceTypes();
    const { user, subscription } = useUser();
    const { fetchBillingHistory } = useAPI();

    const [loading, setLoading] = useState(true);

    const [paymentMethod, setPaymentMethod] = useState({});
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [transactions, setTransactions] = useState([]);

    const [loadingError, setLoadingError] = useState(false);
    const [updatePaymentPath, setUpdatePaymentPath] = useState(ScreenNames.UpdatePayment);
    const [addPaymentPath, setAddPaymentPath] = useState(ScreenNames.AddPayment);
    
    const fetchApi = async() => {
        try {
            setLoading(true);
            const res = await fetchBillingHistory({ account: user.id, order: 'desc' });
            setTransactions(res.data);

            let paymentMethods = [];
            _.map(user?.payment_methods?.data, i => {
                if (i.type === 'CreditCard') {
                    paymentMethods.push(i);
                    if (i.primary) {
                        setPaymentMethod(i);
                    }
                }
            });

            setPaymentMethods(paymentMethods);
            setLoading(false);
            setLoadingError(false);
        } catch (error) {
            console.log({ error });
            setLoadingError(true);
            setLoading(false);  
        }
    }

    // Check if credit card has account, or expiration_date null values, if so then return true else false
    const canDisplay = ({ credit_card }) => {
        const { account, expiration_date, last_digits } = credit_card;
        if (!account || !expiration_date || !last_digits) return false;
        return true;        
    }

    useEffect(() => {
        (async() => {
            await fetchApi();
        })();
    },[]);

    useEffect(() => {
        (async() => {
            if(history.location.pathname === ScreenNames.Billing) {
                setUpdatePaymentPath(ScreenNames.UpdatePaymentManage);
                setAddPaymentPath(ScreenNames.AddPaymentManage);
            }
        })();
    },[]);    

    return (
        <Card>
            <CardBody>
                <CardContent paddingTop={2} paddingBottom={2}>
                    <CardTitle variant={styleSettings.contentBox.headerVariant}>{Strings.paymentMethod}</CardTitle>
                </CardContent>
            </CardBody>
            <Divider />
            {loading && 
                <>
                    <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                        <Loader small />
                        <Typography children={`${Strings.loading} ${Strings.paymentMethod}`} style={{ marginTop: 10 }} />
                    </Flex>
                </>
            }

            {!loading && paymentMethod?.id && 
                <>                    
                    {_.map(paymentMethods, pm => <CardBody paddingTop={2} paddingBottom={2}>
                        <CardContent>
                            <CardTitle variant={styleSettings.contentBox.lineHeaderVariant}>{Strings.creditCard}</CardTitle>
                            <CardSubtitle variant={styleSettings.contentBox.lineSubHeaderVariant}>{`***${_.get(pm?.credit_card,'last_digits','XXXX')} exp. ${_.get(pm?.credit_card, 'expiration_date', '00')?.slice(4, 6) || '00'}/${_.get(pm?.credit_card, 'expiration_date', '0000')?.slice(2, 4) || '0000'}`}</CardSubtitle>
                            {canDisplay(pm) && <Link 
                            children={'Update'}
                            to={`${updatePaymentPath}?id=${pm.id}`}
                            disabled={
                                (
                                    _.size(transactions) > 0 && 
                                    !subscription?.id &&
                                    history.location.pathname === ScreenNames.Billing
                                ) ?
                                true : 
                                false 
                            }
                            />}
                        </CardContent>
                    </CardBody>)}
                </>                
            }

            {!loading && !paymentMethod?.id && !loadingError && 
                <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                    <Typography children={`No ${Strings.paymentMethod} Available`} style={{ marginTop: 10 }} />
                </Flex>
            }

            {loadingError &&
                <Flex direction="column" alignItems="center" justifyContent="center" style={{ minHeight: 150 }}>
                    <Typography children={`${Strings.unableToLoad} ${Strings.paymentMethod}`} style={{ marginTop: 10 }} />
                </Flex>
            }                
            
            <Divider />
            <CardBody>
                {!loadingError && <Button
                    style={{ height: 'auto' }}
                    children={'Add Payment Method'}
                    fullWidth
                    onClick={() => window.location.href = addPaymentPath}
                    disabled={
                        (
                            _.size(transactions) > 0 && 
                            !subscription?.id &&
                            history.location.pathname === ScreenNames.Billing
                        ) ?
                        true : 
                        false 
                    }/>
                }
                {/* {!loadingError && <PaymentMethodModal fullWidth disabled={loading} onComplete={fetchApi} />} */}
                {loadingError && <Button children={Strings.refresh} fullWidth disabled={loading} onClick={fetchApi} />}
            </CardBody>
            
        </Card>
    )
}