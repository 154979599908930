import React, { useEffect } from "react";
import _ from 'lodash';
import { useHistory } from "react-router-dom";
import { Flex, Typography } from '@strapi/design-system';
import PaymentBox from "../../components/PaymentBox";
import Strings from "../../constants/Strings";
import { styleSettings } from "../../config";
import useUser from "../../hooks/useUser";
import useGoogleAnalytics from "../../hooks/useGoogleAnalytics";
import useDeviceTypes from "../../hooks/useDeviceTypes";
import ScreenNames from "../../constants/ScreenNames";
import { Link } from '@strapi/design-system/Link';

export default function UpdatePaymentScreen(props) {

    const history = useHistory();
    const { analytics } = useGoogleAnalytics();
    const { isMobileTablet, isWidth } = useDeviceTypes();
    const { token, cart } = useUser();

    const queryParams = new URLSearchParams(window.location.search);
    const cardId = queryParams.get('id');

    useEffect(() => {

        /* Track a page view */
        // analytics.page();

    },[]);

    if(!token) history.push(ScreenNames.SignIn);
    if(!cardId) history.push(ScreenNames.Billing);
    return (
        <Flex direction="column" alignItems='center'>
            <Typography variant={styleSettings.pageHeaders.variant}>{Strings.updatePaymentDetails}</Typography>
            <Typography variant={styleSettings.pageSubHeaders.variant} textColor={styleSettings.pageSubHeaders.textColor} style={{ marginTop: 5 }}>{Strings.paymentScreen.subtitle}</Typography>                
            <Flex direction='column' alignItems='center' style={{ width: isMobileTablet ? '95vw' : 500, marginTop: 30 }}>
                {/* <Link to="#" onClick={() => history.goBack()} startIcon={<ArrowLeft />} style={{ marginBottom: 25 }}>
                    GO BACK
                </Link> */}
                <PaymentBox update={true} to={ScreenNames.PurchaseConfirmation} />
                <Link to={ScreenNames.PurchaseConfirmation}>
                    Cancel
                </Link>
            </Flex>            
        </Flex>
    )
}